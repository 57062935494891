/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from 'react';
import SITE_CONFIG from '../SiteController/SiteController';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-validation';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import airlinesName from '../controller/Controller';

import '../resources/css/payment-style.css';
//import AA from '../resources/images/airline-logo/AA.png';
import Footer2 from '../components/Footer/footer2';
import SeatRed from '../resources/images/icons/seat_icon_white.png';
import Awindow from '../resources/images/icons/a_window-seat.png';
import Middle from '../resources/images/icons/ab_middle-seat.png';
import Aisle from '../resources/images/icons/abc_aisle-seat.png';
import BagIcon from '../resources/images/icons/bagicon-white.png';
import bagIcon2 from '../resources/images/icons/icon-bag.png';
import CarryOn from '../resources/images/icons/carryon_bag.png';
import yesMark from '../resources/images/yesmark.png';
import yesMarkBlank from '../resources/images/yesmark-blank.png';
import userIcon from '../resources/images/icons/user-icon.png';
import yesFlight from '../resources/images/yes-flight.png';
import yesIcon from '../resources/images/yes-icon.png';
import Included from '../resources/images/icons/included.png';
import bundleWhite from '../resources/images/icons/bundle_white.png';
import MetaTitle from './metaTitle';
//import bundleIcon from '../resources/images/icons/bundle_icon.png';
//import flightIcon from '../resources/images/icons/flight_icon.png';
//import flightProtection from '../resources/images/icons/flight_protection.png';
//import magazine from '../resources/images/icons/online_magazines.png';
//import smartDelay from '../resources/images/icons/icon_smartdelay.png';
//import travelSim from '../resources/images/icons/travele_sim3GB.png';
//import lostBag from '../resources/images/icons/lost_bag_icon.png';

function formatDateAndTime(isoDate) {
    const dateOptions = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    };
  
    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    const formattedDate = new Date(isoDate);
    const formattedDateString = new Intl.DateTimeFormat('en-US', dateOptions).format(formattedDate);
    const formattedTimeString = new Intl.DateTimeFormat('en-US', timeOptions).format(formattedDate);
  
    return { formattedDateString, formattedTimeString };
  }
  
  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hrs ${remainingMinutes} min`;
  }

export default function PaymentPage() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
    const location = useLocation();
  const { resultID } = useParams();
  const paymentState = location.state;
  const urlSearchID = new URLSearchParams(location.search).get('searchID');
  ////console.log('paymentState:', paymentState);
  // Extract flightResult and airport from the paymentState object
  const { flightResult, airport, formData, baggageInformation } = paymentState || {};
  const searchParams = new URLSearchParams(location.search);
  const gesearchID = searchParams.get('searchID');
  const [departDetailsVisible, setDepartDetailsVisible] = useState(false);
  const [returnDetailsVisible, setReturnDetailsVisible] = useState(false);
  //const [totalPriceDisplay, setTotalPriceDisplay] = useState(0);
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [email, setEmail] = useState('');

//   const handlePhoneNumberChange = (e) => {
//     setPhoneNumber(e.target.value);
//   };

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleSubmit = () => {
//     fetch('http://localhost:3001/sendEmail', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ phoneNumber, email }),
//     })
//       .then((response) => {
//         if (response.ok) {
//           //console.log('Email sent successfully');
//         } else {
//           //console.error('Error sending email');
//         }
//       })
//       .catch((error) => {
//         //console.error('Error sending email', error);
//       });
//   };
const [selectedCard, setSelectedCard] = useState(null);
const [selectedDepFirstCheckbox, setSelectedDepFirstCheckbox] = useState(null);
const [selectedDepCheckbox, setSelectedDepCheckbox] = useState(null);
const [addToBooking, setAddToBooking] = useState(false);
const [selectedRetFirstCheckbox, setSelectedRetFirstCheckbox] = useState(null);
const [selectedRetCheckbox, setSelectedRetCheckbox] = useState(null);
const [optionalDetails, setOptionalDetails] = useState(false);

const navigate = useNavigate();

const handleOptionalDetails = () => {
  setOptionalDetails(!optionalDetails);
}
// const [individualCheckboxes, setIndividualCheckboxes] = useState({
//     flightAlert: false,
//     flightProtection: false,
//     onlineMagazines: false,
//     smartDelay: false,
//     travelESIM: false,
//     lostBagProtection: false,
//   });

const [marginTop, setMarginTop] = useState(0);
const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [flightCancel, setFlightCancel] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
const [fullBundleChecked, setFullBundleChecked] = useState(false);
const [firstDepFirstBaggageChecked, setFirstDepFirstBaggageChecked] = useState(false);
const [thirdDepFirstBaggageChecked, setThirdDepFirstBaggageChecked] = useState(false);
const [forthDepFirstBaggageChecked, setForthDepFirstBaggageChecked] = useState(false);
const [fifthDepFirstBaggageChecked, setFifthDepFirstBaggageChecked] = useState(false);
const [sixthDepFirstBaggageChecked, setSixthDepFirstBaggageChecked] = useState(false);
const [seventhDepFirstBaggageChecked, setSeventhDepFirstBaggageChecked] = useState(false);
const [eirthDepFirstBaggageChecked, setEirthDepFirstBaggageChecked] = useState(false);
const [ninthDepFirstBaggageChecked, setNinthDepFirstBaggageChecked] = useState(false);
  const [secondDepFirstBaggageChecked, setSecondDepFirstBaggageChecked] = useState(false);
  const [firstRetFirstBaggageChecked, setFirstRetFirstBaggageChecked] = useState(false);
  const [secondRetFirstBaggageChecked, setSecondRetFirstBaggageChecked] = useState(false);
  const [thirdRetFirstBaggageChecked, setThirdRetFirstBaggageChecked] = useState(false);
  const [fourthRetFirstBaggageChecked, setFourthRetFirstBaggageChecked] = useState(false);
  const [fifthRetFirstBaggageChecked, setFifthRetFirstBaggageChecked] = useState(false);
  const [sixthRetFirstBaggageChecked, setSixthRetFirstBaggageChecked] = useState(false);
  const [seventhRetFirstBaggageChecked, setSeventhRetFirstBaggageChecked] = useState(false);
  const [eirthRetFirstBaggageChecked, setEirthRetFirstBaggageChecked] = useState(false);
  const [ninthRetFirstBaggageChecked, setNinthRetFirstBaggageChecked] = useState(false);
const [firstDepBaggageChecked, setFirstDepBaggageChecked] = useState(false);
const [secondDepBaggageChecked, setSecondDepBaggageChecked] = useState(false);
const [thirdDepBaggageChecked, setThirdDepBaggageChecked] = useState(false);
const [fourthDepBaggageChecked, setFourthDepBaggageChecked] = useState(false);
const [fifthDepBaggageChecked, setFifthDepBaggageChecked] = useState(false);
const [sixthDepBaggageChecked, setSixthDepBaggageChecked] = useState(false);
const [seventhDepBaggageChecked, setSeventhDepBaggageChecked] = useState(false);
const [eirthDepBaggageChecked, setEirthDepBaggageChecked] = useState(false);
  // const [secondDepBaggageChecked, setSecondDepBaggageChecked] = useState(false);
  const [firstRetBaggageChecked, setFirstRetBaggageChecked] = useState(false);
  const [thirdRetBaggageChecked, setThirdRetBaggageChecked] = useState(false);
  const [fourthRetBaggageChecked, setFourthRetBaggageChecked] = useState(false);
  const [fifthRetBaggageChecked, setFifthRetBaggageChecked] = useState(false);
  const [sixthRetBaggageChecked, setSixthRetBaggageChecked] = useState(false);
  const [seventhRetBaggageChecked, setSeventhRetBaggageChecked] = useState(false);
  const [eirthRetBaggageChecked, setEirthRetBaggageChecked] = useState(false);
  const [baggageInfo, setBaggageInfo] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  //const [paxType, setPaxType] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenReturn, setDropdownOpenReturn] = useState(false);
  const [dropdownOpenCheckedBag, setDropdownOpenCheckedBag] = useState(false);
  const [dropdownOpenCheckedBagReturn, setDropdownOpenCheckedBagReturn] = useState(false);
  const passengerTypes = ["adult", "child", "infant", "infantWs"];
  const [bookingRequest, setBookingRequest] = useState({
    passengers: [{
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      gender: '',
      dobMonth: '',
      dobDay: '',
      dobYear: '',
      dateOfBirth: '',
      contactName: '',
      contactNumber: '',
      tsaPrecheck: '',
      reDressNumber: '',
      splRequest: '',
      }],
    seatSelection: '',
    autocheckIn: '',
    flightCancelation : '',
    bundle: '',
    baggage: '',
    totalPrice: ''
    });

    // useEffect(() => {
    //   const passengers = passengerTypes.map((type) => {
    //     return Array.from({ length: formData[type] || 0 }, () => ({
    //       title: '',
    //       name: '',
    //       middleName: '',
    //       lastName: '',
    //       gender: '',
    //       dobMonth: '',
    //       dobDay: '',
    //       dobYear: '',
    //       paxType: type, // Set the passenger type
    //     }));
    //   }).flat();
    
    //   setBookingRequest((prevBookingRequest) => ({
    //     ...prevBookingRequest,
    //     passengers,
    //   }));
    // }, [formData]);
    
  const [secondRetBaggageChecked, setSecondRetBaggageChecked] = useState(false);
  // const updateSelectedDepCheckbox = () => {
  //   if (firstDepBaggageChecked && secondDepBaggageChecked) {
  //     setSelectedDepCheckbox('both');
  //   } else if (firstDepBaggageChecked) {
  //     setSelectedDepCheckbox('first');
  //   } else if (secondDepBaggageChecked) {
  //     setSelectedDepCheckbox('second');
  //   } else {
  //     setSelectedDepCheckbox(null);
  //   }
  // };

  const updateSelectedDepCheckbox = () => {
    if (firstDepBaggageChecked) {
      setSelectedDepCheckbox('1');
    } else if (secondDepBaggageChecked) {
      setSelectedDepCheckbox('2');
    } else if (thirdDepBaggageChecked) {
      setSelectedDepCheckbox('3');
    }  else if (fourthDepBaggageChecked) {
      setSelectedDepCheckbox('4');
    }  else if (fifthDepBaggageChecked) {
      setSelectedDepCheckbox('5');
    }  else if (sixthDepBaggageChecked) {
      setSelectedDepCheckbox('6');
    } else if (seventhDepBaggageChecked) {
      setSelectedDepCheckbox('7');
    } else if (eirthDepBaggageChecked) {
      setSelectedDepCheckbox('8');
    }  else {
      setSelectedDepCheckbox(null);
    }
  };

  // const updateSelectedDepFirstCheckbox = () => {
  //   if (firstDepFirstBaggageChecked && secondDepFirstBaggageChecked) {
  //     setSelectedDepFirstCheckbox('both');
  //   } else if (firstDepFirstBaggageChecked) {
  //     setSelectedDepFirstCheckbox('first');
  //   } else if (secondDepFirstBaggageChecked) {
  //     setSelectedDepFirstCheckbox('second');
  //   } else {
  //     setSelectedDepFirstCheckbox(null);
  //   }
  // };

  const updateSelectedDepFirstCheckbox = () => {
    if (firstDepFirstBaggageChecked) {
      setSelectedDepFirstCheckbox('1');
    } else if (secondDepFirstBaggageChecked) {
      setSelectedDepFirstCheckbox('2');
    } else if (thirdDepFirstBaggageChecked) {
      setSelectedDepFirstCheckbox('3');
    }  else if (forthDepFirstBaggageChecked) {
      setSelectedDepFirstCheckbox('4');
    }  else if (fifthDepFirstBaggageChecked) {
      setSelectedDepFirstCheckbox('5');
    }  else if (sixthDepFirstBaggageChecked) {
      setSelectedDepFirstCheckbox('6');
    } else if (seventhDepFirstBaggageChecked) {
      setSelectedDepFirstCheckbox('7');
    } else if (eirthDepFirstBaggageChecked) {
      setSelectedDepFirstCheckbox('8');
    } else if (ninthDepFirstBaggageChecked) {
      setSelectedDepFirstCheckbox('9');
    } else {
      setSelectedDepFirstCheckbox(null);
    }
  };

  // const updateSelectedRetCheckbox = () => {
  //   if (firstRetBaggageChecked && secondRetBaggageChecked) {
  //     setSelectedRetCheckbox('both');
  //   } else if (firstRetBaggageChecked) {
  //     setSelectedRetCheckbox('first');
  //   } else if (secondRetBaggageChecked) {
  //     setSelectedRetCheckbox('second');
  //   } else {
  //     setSelectedRetCheckbox(null);
  //   }
  // };
  const updateSelectedRetCheckbox = () => {
    if (firstRetBaggageChecked) {
      setSelectedRetCheckbox('1');
    } else if (secondRetBaggageChecked) {
      setSelectedRetCheckbox('2');
    } else if (thirdRetBaggageChecked) {
      setSelectedRetCheckbox('3');
    }  else if (fourthRetBaggageChecked) {
      setSelectedRetCheckbox('4');
    }  else if (fifthRetBaggageChecked) {
      setSelectedRetCheckbox('5');
    }  else if (sixthRetBaggageChecked) {
      setSelectedRetCheckbox('6');
    } else if (seventhRetBaggageChecked) {
      setSelectedRetCheckbox('7');
    } else if (eirthRetBaggageChecked) {
      setSelectedRetCheckbox('8');
    }  else {
      setSelectedRetCheckbox(null);
    }
  };

  // const updateSelectedRetFirstCheckbox = () => {
  //   if (firstRetFirstBaggageChecked && secondRetFirstBaggageChecked) {
  //     setSelectedRetFirstCheckbox('both');
  //   } else if (firstRetFirstBaggageChecked) {
  //     setSelectedRetFirstCheckbox('first');
  //   } else if (secondRetFirstBaggageChecked) {
  //     setSelectedRetFirstCheckbox('second');
  //   } else {
  //     setSelectedRetFirstCheckbox(null);
  //   }
  // };
  const updateSelectedRetFirstCheckbox = () => {
    if (firstRetFirstBaggageChecked) {
      setSelectedRetFirstCheckbox('1');
    } else if (secondRetFirstBaggageChecked) {
      setSelectedRetFirstCheckbox('2');
    } else if (thirdRetFirstBaggageChecked) {
      setSelectedRetFirstCheckbox('3');
    }  else if (fourthRetFirstBaggageChecked) {
      setSelectedRetFirstCheckbox('4');
    }  else if (fifthRetFirstBaggageChecked) {
      setSelectedRetFirstCheckbox('5');
    }  else if (sixthRetFirstBaggageChecked) {
      setSelectedRetFirstCheckbox('6');
    } else if (seventhRetFirstBaggageChecked) {
      setSelectedRetFirstCheckbox('7');
    } else if (eirthRetFirstBaggageChecked) {
      setSelectedRetFirstCheckbox('8');
    } else if (ninthRetFirstBaggageChecked) {
      setSelectedRetFirstCheckbox('9');
    } else {
      setSelectedRetFirstCheckbox(null);
    }
  };
  ////console.log(firstDepFirstBaggageChecked)
 // const [selectedProducts, setSelectedProducts] = useState([]);
  //const [formSubmitted, setFormSubmitted] = useState(false);
  

 
////console.log(flightResult)
  // Check if flightResult is defined and an array
  if (!Array.isArray(flightResult)) {
    return <p>Flight data is missing or invalid. Please select a valid flight.</p>;
  }
  //let selectedFlight;
// if (!flightResult.resultId === '1000'){
  const selectedFlight = flightResult.find((flight) => flight.resultId === parseInt(resultID));
  const selectedBaggage = baggageInformation.find((baggage) => baggage.id === selectedFlight.bagOptionRef[0]);
  //console.log(selectedBaggage)
// } 
//   //console.log(flightResult.resultId)
//   const selectedFlight = flightResult;

  // Find the selected flight based on the resultID
  

  //const Passengers = formData.find((travellerNumber) => travellerNumber.totalTravellers === parseInt(totalTravellers))
  const totalPassengers = (formData && formData.adults ? parseInt(formData.adults) : 0) + (formData && formData.child ? parseInt(formData.child) : 0) + (formData && formData.infants ? parseInt(formData.infants) : 0) + (formData && formData.infantsWs ? parseInt(formData.infantsWs) : 0);
  ////console.log(formData);
  if (!selectedFlight) {
    // Handle the case when no matching flight is found
    return <p>Flight not found. Please select a valid flight.</p>;
  }

  const handleCardClick = (index) => {
    setSelectedCard(index);
  };

  const handleDepCheckboxClick = (index) => {
    //setSelectedDepCheckbox(index);
  };

  const handleRetCheckboxClick = (index) => {
	setSelectedRetCheckbox(index);
  }

  const handleAddToBooking = () => {
	setAddToBooking(true);
  }

  

  

  // Function to handle individual checkbox changes
  

  
//   const handleChange = (e) => {
//     const { name, value } = e.target;
    
//     // Clear the error message for the current field
//     setErrors({ ...errors, [name]: '' });
    
//     // Update the billingForm state with the new value
//     setBillingForm({ ...billingForm, [name]: value });
//   };
  const handleSubmit = async (e) => {
    //e.preventDefault();

    try {
        if (!phoneNumber || !email) {
            alert('Please fill in both phone number and email.');
            return;
          }
      // Send data to the server
      const response = await axios.post(`${apiIP}:6060/api/submit-form`, {
        phoneNumber,
        email,
      });

      if (response.status === 200) {
        //alert('Form submitted successfully!');
        // Reset form fields
        //setPhoneNumber('');
        //setEmail('');
      } else {
        //alert('Form submission failed.');
      }
    } catch (error) {
      ////console.error(error);
      //alert('Form submission failed.');
    }
  };


  const handleBlur = () => {
    // Trigger form submission when either input field loses focus
    if (phoneNumber.trim() !== '' && email.trim() !== '') {
        handleSubmit();
      }
  };


  useEffect(() => {
    // Add a "beforeunload" event listener to prompt the user
    // before leaving the page with unsaved changes
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopstate);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBeforeUnload = (e) => {
    if (phoneNumber.trim() !== '' || email.trim() !== '') {
      // Display a confirmation message
      e.preventDefault();
      e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      // Call handleBlur to trigger validation if the user confirms leaving
      handleBlur();
    }
  };

  const handlePopstate = () => {
    // Handle the popstate event (user navigates back in history)
    handleBlur();
  };


  const [errors, setErrors] = useState({ phoneNumber: '', email: '', title: '', firstname: '', lastName: '', gender: '', dobDay: '', dobMonth: '', dobYear: '' });
  const nameRef = useRef(null);
  const titleRef = useRef(null);
  const lastNameRef = useRef(null);
  const genderRef = useRef(null);
  const dobMonthRef = useRef(null);
  const dobDayRef = useRef(null);
  const dobYearRef = useRef(null);
  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setBillingForm({ ...billingForm, [name]: value });
//     // Clear validation error when the user starts typing in a field
//     setErrors({ ...errors, [name]: '' });
//   };
useEffect(() => {
    const handleScroll = () => {
      // Determine how much to offset the margin-top based on the scroll position
      const scrollY = window.scrollY;

      // You can adjust this value to control the offset as per your requirement
      // Set the margin-top to -166px when scrolling down
      const newMarginTop = scrollY > 0 ? -59 : 0;

      // Update the marginTop state
      setMarginTop(newMarginTop);
    };

    // Add a scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', });
    }
  };

  // useEffect(() => {
  //   // Calculate paxTypes whenever dobYear changes for each passenger
  //   const updatedPaxTypes = bookingRequest.passengers.map((passenger) => {
  //     const { dobYear } = passenger;
  //     if (dobYear) {
  //       const currentDate = new Date();
  //       const birthDate = new Date(dobYear);
  //       const age = currentDate.getFullYear() - birthDate.getFullYear();

  //       let category = "";
  //       if (age >= 18) {
  //         category = "Adult";
  //       } else if (age >= 2) {
  //         category = "Child";
  //       } else if (age < 2) {
  //         category = "Infant";
  //       }

  //       return category;
  //     }
  //     return "";
  //   });

  //   setPaxType(updatedPaxTypes);
  // }, [bookingRequest.passengers]);

  

  const updatePassengerInfo = (passengerIndex, field, value) => {
    setBookingRequest((prevState) => {
      const updatedPassengers = [...prevState.passengers];
      updatedPassengers[passengerIndex] = {
        ...updatedPassengers[passengerIndex],
        [field]: value,
      };
  
      return {
        ...prevState,
        passengers: updatedPassengers,
      };
    });
  }; 

//   const handleFinalSubmit = () => {
//     // Create a function to validate the form fields and return a list of fields with empty values
//     // Create a function to validate the form fields and return a list of fields with empty values
//     if (!phoneNumber) {
//       errors.phoneNumber = 'Please fill this field';
//       phoneNumberRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
//     }
//     if (!email) {
//       errors.email = 'Please fill this field';
//       emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
//     }
//     const passengers = bookingRequest.passengers;

// const newErrors = passengers.map((passenger, index) => {
//   const errors = {};

//   if (totalPassengers === 1) {
//     ////console.log('passenger', totalPassengers);
//     ////console.log('DOB', passenger.dobYear)
//     const dobYear = parseInt(passenger.dobYear, 10); 
//     ////console.log(dobYear)// Convert to an integer
//     if (dobYear) {
//       const paxAge = 2023 - dobYear 

//       if(paxAge < 18){
//         errors.dob = 'Please fill all date of birth fields';
//         alert('Passenger Age should be 18 or above 18')
//       }
      
//     } 
//   }

//   if (passenger.name === undefined || passenger.name === '') {
//     errors.name = 'Please fill this field';
//     nameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
//   }
//   if (passenger.title === undefined || passenger.title === '') {
//     errors.title = 'Please fill this field';
//     titleRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
//   }
//   if (passenger.lastName === undefined || passenger.lastName === '') {
//     errors.lastName = 'Please fill this field';
//     lastNameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
//   }
//   if (passenger.gender === undefined || passenger.gender === '') {
//     errors.gender = 'Please fill this field';
//     genderRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
//   }
//   if (
//     passenger.dobDay === undefined ||
//     passenger.dobMonth === undefined ||
//     passenger.dobYear === undefined
//   ) {
//     errors.dob = 'Please fill all date of birth fields';
//     dobDayRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
//   }

//   return errors;
// });

// // Check if any validation errors exist
// const hasErrors = newErrors.some((errors) => Object.keys(errors).length > 0);

// if (hasErrors) {
//     setErrors(newErrors);

//     alert("Please Fill all the Necessary Fields");
//     ////console.log(errors.name)
//   }
//   if(bookingRequest.passengers.length === 0){
//     alert("Please Fill all the Necessary Fields");
//   }
//    else {
    
//       // Form is valid, you can proceed with form submission here
//       const newBookingRequest = {
//         ...bookingRequest,
//         phoneNumber: phoneNumber,
//         email: email,
//         seatSelection: selectedCard,
//         autocheckIn: addToBooking,
//         flightCancelation: flightCancel,
//         bundle: selectedProducts,
//         baggage: {
//           firstDepFirstBaggage: firstDepFirstBaggageChecked,
//           secondDepFirstBaggage: secondDepFirstBaggageChecked,
//           firstDepCheckedBaggage: firstDepBaggageChecked,
//           secondDepCheckedBaggage: secondDepBaggageChecked,
//           firstRetFirstBaggage: firstRetFirstBaggageChecked,
//           secondRetFirstBaggage: secondRetFirstBaggageChecked,
//           firstRetCheckedBaggage: firstRetBaggageChecked,
//           secondRetCheckedBaggage: secondRetBaggageChecked,
//         },
//         totalPrice: totalPriceDisplay,
        
//       };
//       if (flightCancel) {
//         newBookingRequest.ancillaryProduct = [
//           {
//             productId: 0,
//             name: 'CT',
//             mostPopular: true,
//             price: 80.0,
//             conditionList: [],
//             selectionType: 'Yes',
//             supplierNumber: '',
//             supplierLookup: '',
//             fullName: 'Standard Tickets',
//           },
//         ];
//       }
//       const cardState = { selectedFlight, airport, formData, newBookingRequest };
//       //console.log('Form data submitted:', cardState);
//       navigate(`/card?searchID=${formData.searchID}`, { state: cardState });
//     }
    
//   };

useEffect(() => {
  $.validator.addMethod('titlereq', function (value) {
    return (value !== '0');
}, "<i class='fa fa-angle-double-right'></i> Please select the Gender of the traveler");
  $.validator.addMethod('genderreq', function (value) {
    return value !== '0';
  }, 'Please Select Gender');
  $.validator.addMethod('selectDate', function (value) {
    return (value !== 'Day');
}, "Please Select Date");

$.validator.addMethod('selectMonth', function (value) {
    return (value !== '0');
}, "Please Select Month");

$.validator.addMethod('selectYear', function (value) {
    return (value !== 'Year');
}, "Please Select Year");
  $('#myForm').validate({
    rules: {
      MobileNo: {
        required: true,
      },
      EMAIL: {
        required: true,
      },
      Title: {
        required: true,
        titlereq: true,
      },
      firstName: {
        required: true,
        minlength: 3,
      },
      Gender: {
        required: true,
        genderreq: true,
      },
      DOBDay: {
        required: true,
        selectDate: true,
      },
      DOBMonth: {
        required: true,
        selectMonth: true,
      },
      DOBYear: {
        required: true,
        selectYear: true,
      },
    },
    errorElement: 'div',
                		    errorClass: 'show-error',
                		    errorPlacement: function (error, element) {
                		    	
                		    	// if (element.attr("name") === "passengerDetails[0].firstName" || element.attr("name") === "passengerDetails[0].lastName" || element.attr("name") === "passengerDetails[0].Gender" || element.attr("name") === "passengerDetails[0].dobDay" || element.attr("name") === "passengerDetails[0].dateOfMonth" || element.attr("name") === "passengerDetails[0].dateOfYear") {
                		    	// 	error.insertAfter(element);
                		    	// }
                		    	// else{
                		    		error.insertAfter(element);
                		    	// }
                        },
    messages: {
      MobileNo: {
        required: 'Please Enter Phone number'
      },
      EMAIL: {
        required: 'Please enter Email Address',
        email: 'Provide Correct Email',
      },
      Title: {
        required: 'Please Select The Title',
      },
      firstName: {
        required: 'Please enter your name',
        minlength: 'Name should be at least 3 characters',
      },
      Gender: {
        required: 'Please enter your Gender',
      },
      DOBDay: {
        required: 'Please enter the birth day',
      },
      DOBMonth: {
        required: 'Please enter your birth Month',
      },
      DOBYear: {
        required: 'Please enter the birth Year',
      },
    },
    // submitHandler: function (form) {
    //   setIsFormValid(true);
    //   //console.log('Form submitted');
    //   return false; // Prevent form submission for this example
    // },
  });
  $('#mailer').validate({
    rules: {
      MobileNo: {
        required: true,
        minlength: 10,
      },
      EMAIL: {
        required: true,
        email: true,
      },
    },
    errorElement: 'div',
                		    errorClass: 'show-error',
                		    errorPlacement: function (error, element) {
                		    	
                		    	// if (element.attr("name") === "passengerDetails[0].firstName" || element.attr("name") === "passengerDetails[0].lastName" || element.attr("name") === "passengerDetails[0].Gender" || element.attr("name") === "passengerDetails[0].dobDay" || element.attr("name") === "passengerDetails[0].dateOfMonth" || element.attr("name") === "passengerDetails[0].dateOfYear") {
                		    	// 	error.insertAfter(element);
                		    	// }
                		    	// else{
                		    		error.insertAfter(element);
                		    	// }
                        },
    messages: {
      MobileNo: {
        required: 'Please Enter Phone number'
      },
      EMAIL: {
        required: 'Please enter Email Address',
        email: 'Provide Correct Email',
      },
    }
  });

  
}, []);

const handleFinalSubmit = (e) => {
  // if (!phoneNumber) {
  //          errors.phoneNumber = 'Please fill this field';
  //         phoneNumberRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //        }
  //        if (!email) {
  //          errors.email = 'Please fill this field';
  //          emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //       }
  e.preventDefault();
  const form = $('#myForm');
  const phoneNumberMailValid = $('#mailer');

  if (form.valid() && phoneNumberMailValid.valid()) {
  const newBookingRequest = {
             ...bookingRequest,
             phoneNumber: phoneNumber,
             email: email,
             seatSelection: selectedCard,
             autocheckIn: addToBooking,
             flightCancelation: flightCancel,
             bundle: selectedProducts,
            //  baggage: {
            //    firstDepFirstBaggage: firstDepFirstBaggageChecked,
            //    secondDepFirstBaggage: secondDepFirstBaggageChecked,
            //    firstDepCheckedBaggage: firstDepBaggageChecked,
            //    secondDepCheckedBaggage: secondDepBaggageChecked,
            //    firstRetFirstBaggage: firstRetFirstBaggageChecked,
            //    secondRetFirstBaggage: secondRetFirstBaggageChecked,
            //    firstRetCheckedBaggage: firstRetBaggageChecked,
            //    secondRetCheckedBaggage: secondRetBaggageChecked,
            //  },
            baggage: [
              {
                DepBaggageSelectedCarryOnBag: selectedDepFirstCheckbox,
                TotalPriceBaggageCarryOnBag : (firstDepFirstBaggageChecked === true ? 20 : 
                  secondDepFirstBaggageChecked === true ? 40 : 
                  thirdDepFirstBaggageChecked === true ? 60 : 
                  forthDepFirstBaggageChecked === true ? 80 : 
                  fifthDepFirstBaggageChecked === true ? 100 : 
                  sixthDepFirstBaggageChecked === true ? 120 : 
                  seventhDepFirstBaggageChecked === true ? 140 : 
                  eirthDepFirstBaggageChecked === true ? 160 : 0 ),
                DepBaggageSelectedCheckedBag: selectedDepCheckbox,
                TotalPriceBaggageCheckedBag : (firstDepBaggageChecked === true ? 35 : 
                  secondDepBaggageChecked === true ? 70 : 
                  thirdDepBaggageChecked === true ? 105 : 
                  fourthDepBaggageChecked === true ? 140 : 
                  fifthDepBaggageChecked === true ? 175 : 
                  sixthDepBaggageChecked === true ? 210 : 
                  seventhDepBaggageChecked === true ? 245 : 
                  eirthDepBaggageChecked === true ? 280 : 0 )
              },
              {
                RetBaggageSelectedCarryOnBag: selectedRetFirstCheckbox,
                TotalPriceBaggageCarryOnBag : (firstRetFirstBaggageChecked === true ? 20 : 
                  secondRetFirstBaggageChecked === true ? 40 : 
                  thirdRetFirstBaggageChecked === true ? 60 : 
                  fourthRetFirstBaggageChecked === true ? 80 : 
                  fifthRetFirstBaggageChecked === true ? 100 : 
                  sixthRetFirstBaggageChecked === true ? 120 : 
                  seventhRetFirstBaggageChecked === true ? 140 : 
                  eirthRetFirstBaggageChecked === true ? 160 : 0 ),
                RetBaggageSelectedCheckedBag: selectedRetCheckbox,
                TotalPriceBaggageCheckedBag : (firstRetBaggageChecked === true ? 35 : 
                  secondRetBaggageChecked === true ? 70 : 
                  thirdRetBaggageChecked === true ? 105 : 
                  fourthRetBaggageChecked === true ? 140 : 
                  fifthRetBaggageChecked === true ? 175 : 
                  sixthRetBaggageChecked === true ? 210 : 
                  seventhRetBaggageChecked === true ? 245 : 
                  eirthRetBaggageChecked === true ? 280 : 0 )
              }
            ],
             totalPrice: totalPriceDisplay,
            
           };
           if (flightCancel) {
             newBookingRequest.ancillaryProduct = [
               {
                 productId: 0,
                 name: 'CT',
                 mostPopular: true,
                 price: 80.0,
                 conditionList: [],
                 selectionType: 'Yes',
                 supplierNumber: '',
                 supplierLookup: '',
                 fullName: 'Standard Tickets',
               },
             ];
           }
           const cardState = { selectedFlight, airport, formData, newBookingRequest, selectedBaggage };
           //console.log('Form data submitted:', cardState);
           navigate(`/card?searchID=${formData.searchID}`, { state: cardState });
           //navigate(`/card?searchID=${urlSearchID}`, { state: cardState });
          } else {
            ////console.log('Form validation failed. Submission prevented.');
          }
};
  


  

  

  const toggleDepCollapse = () => {
   setDepartDetailsVisible(true);
    setReturnDetailsVisible(false);
  };

  const toggleRetCollapse = () => {
    setDepartDetailsVisible(false);
    setReturnDetailsVisible(true);
  };

  const toggleClose = () => {
    setDepartDetailsVisible(false);
    setReturnDetailsVisible(false);
  }


  




// const bundleOffer = {
//     id: "83763d0f-76d3-450d-8532-81c4e591da02",
//     currency: "USD",
//     fee: {
//       percent: 0.0,
//       minimum: 0.0
//     },
//     passengers_count: 1,
//     products: [
//       {
//         category: "LUGGAGE_PROTECTION",
//         id: "3b14a93c-967b-43ca-bccc-333752964200",
//         name: "Lost Luggage Protection",
//         pricing_type: "PASSENGER",
//         pricing: {
//           price: "5.00",
//           fee: "0.50",
//           total: "5.50"
//         },
//         bundled_pricing: {
//           price: "4.50",
//           fee: "0.45",
//           total: "4.95"
//         },
//         price: "5.50",
//         bundled_price: "4.95",
//         icon_url: "https://app.tripadd.com/assets/tripadd/products/luggage_protection.png",
//         images: [
//           {
//             url: "https://assets.tripadd.com/product-images/7_luggage_protection.jpg"
//           }
//         ],
//         description: "Track and expedite the return of your lost luggage. Receive real-time push notifications regarding the status of your bags. Collect $1,000 per bag that is not returned within 96 hours. (Up to 2 bags per person.)",
//         short_description: "Track and expedite the return of your lost luggage.",
//         quantity: 1,
//         age_categories: [
//           "ADULT",
//           "CHILD",
//           "INFANT"
//         ],
//         cancellation_policy: "FULL_REFUND",
//         refundable_until: "2023-10-18T15:40:00.000Z"
//       },
//       {
//         category: "FLIGHT_NOTIFICATIONS",
//         id: "c6420b5c-11fa-4e0b-9541-bf07466f058e",
//         name: "Flight Alerts",
//         pricing_type: "GROUP",
//         pricing: {
//           price: "2.50",
//           fee: "0.25",
//           total: "2.75"
//         },
//         bundled_pricing: {
//           price: "2.00",
//           fee: "0.20",
//           total: "2.20"
//         },
//         price: "2.75",
//         bundled_price: "2.20",
//         icon_url: "https://app.tripadd.com/assets/tripadd/products/flight_notifications.png",
//         images: [
//           {
//             url: "https://assets.tripadd.com/product-images/8_flight_notifications.jpg"
//           }
//         ],
//         description: "Stop worrying about flight delays! Receive instant email and SMS notifications about changes to your flight's departure schedule, airport terminal, gates, and baggage belt information. Flights covered: AS1308, AS1309.<br /><br />The service is delivered by TripAdd. The tracking will activate 24 hours before the flight's scheduled departure time. The frequency of notifications will vary per flight. Message and data rates may apply.",
//         short_description: "Stop worrying about flight changes!",
//         quantity: 1,
//         age_categories: [
//           "ADULT",
//           "CHILD",
//           "INFANT"
//         ],
//         cancellation_policy: "FULL_REFUND",
//         refundable_until: "2023-10-19T14:00:00.000Z"
//       }
//     ],
//     expires_at: "2023-09-19T14:29:31.153Z",
//     terms_url: "https://app.tripadd.com/terms/83763d0f-76d3-450d-8532-81c4e591da02"
//   }

//   // Initialize selectedProducts and fullBundleChecked when bundleOffer changes
//   useEffect(() => {
//     const initialSelectedProducts = bundleOffer.products.map(() => false);
//     setSelectedProducts(initialSelectedProducts);
//     setFullBundleChecked(false);
//   }, []);

//   // Function to handle individual checkbox change
//   const handleCheckboxChange = (index) => {
//     const updatedSelectedProducts = [...selectedProducts];
//     updatedSelectedProducts[index] = !selectedProducts[index];
//     setSelectedProducts(updatedSelectedProducts);
//   };

//   // Function to handle the "Add Full Bundle" checkbox change
//   const handleFullBundleChange = () => {
//     const allProductsSelected = selectedProducts.every((isSelected) => isSelected);
//     const newSelectedProducts = bundleOffer.products.map(() => !allProductsSelected);
//     setSelectedProducts(newSelectedProducts);
//   };

//   // Calculate the total price of selected products
//   const totalSelectedPrice = bundleOffer.products.reduce((total, product, index) => {
//     if (selectedProducts[index]) {
//       return total + parseFloat(product.bundled_pricing.price);
//     }
//     return total;
//   }, 0);

  const totalPriceAdults = ((formData && formData.adults ? parseInt(formData.adults) : 0) * selectedFlight.fare.adultFare).toFixed(2);
  const totalPriceChild = ((formData && formData.child ? parseInt(formData.child) : 0) * selectedFlight.fare.childFare).toFixed(2);
  const totalPriceInfant = ((formData && formData.infants ? parseInt(formData.infants) : 0) * selectedFlight.fare.infantFare).toFixed(2);
  const totalPriceInfantWs = ((formData && formData.infantsWs ? parseInt(formData.infantsWs) : 0) * selectedFlight.fare.infantWsFare).toFixed(2);
  const totalPriceSeat = (totalPassengers * 7.99);
  const totalWebCheckInPrice = (totalPassengers * 7.99);

  const totalPrice = selectedFlight.fare.grandTotal;
const roundedPrice = totalPrice; // Rounds to 2 decimal places

const totalTax = selectedFlight.fare.totalTax;
const roundedTax = totalTax.toFixed(2);

const [hasSelectedCardValue, setHasSelectedCardValue] = useState(false); // Add this state variable
const [hasSelectedCardValueADD, setHasSelectedCardValueADD] = useState(false);
const [hasSelectedCardValueflight, setHasSelectedCardValueflight] = useState(false);
const [totalPriceDisplay, setTotalPriceDisplay] = useState(roundedPrice);

//const flightCancellation = 19.99;
const updateTotalPrice = () => {
  let updatedPrice = parseFloat(roundedPrice);

  // Calculate the total price based on selected products
  // selectedProducts.forEach((isSelected, index) => {
  //   if (isSelected) {
  //     const productPrice = parseFloat(bundleOffer.products[index].pricing.price);
  //     updatedPrice += productPrice;
  //   }
  // });

  // Add seat price if selected
  if (selectedCard !== null) {
    updatedPrice += 7.99;
  }

  // Add web check-in price if selected
  if (addToBooking) {
    updatedPrice += totalWebCheckInPrice;
  }

  // Add flight cancellation price if selected
  if (flightCancel !== false) {
    updatedPrice += 19.99;
  }

  // Calculate and add selected baggage prices for departure
  // if (selectedDepCheckbox === 0) {
  //   updatedPrice += 5.05; // First Carry-on Bag
  // } else if (selectedDepCheckbox === 1) {
  //   updatedPrice += 5.05; // First Checked Bag
  // }

  //if (firstDepBaggageChecked) updatedPrice += 20;
  if (firstDepFirstBaggageChecked) updatedPrice += 20;
    // if (secondDepBaggageChecked) updatedPrice += 20;
    if (secondDepFirstBaggageChecked) updatedPrice += 40;
    if (thirdDepFirstBaggageChecked) updatedPrice += 60;
    if (forthDepFirstBaggageChecked) updatedPrice += 80;
    if (fifthDepFirstBaggageChecked) updatedPrice += 100;
    if (sixthDepFirstBaggageChecked) updatedPrice += 120;
    if (seventhDepFirstBaggageChecked) updatedPrice += 140;
    if (eirthDepFirstBaggageChecked) updatedPrice += 160;
    if (ninthDepFirstBaggageChecked) updatedPrice += 180;

    if (firstDepBaggageChecked) updatedPrice += 35;
    // if (secondDepBaggageChecked) updatedPrice += 20;
    if (secondDepBaggageChecked) updatedPrice += 70;
    if (thirdDepBaggageChecked) updatedPrice += 105;
    if (fourthDepBaggageChecked) updatedPrice += 140;
    if (fifthDepBaggageChecked) updatedPrice += 175;
    if (sixthDepBaggageChecked) updatedPrice += 210;
    if (seventhDepBaggageChecked) updatedPrice += 245;
    if (eirthDepBaggageChecked) updatedPrice += 280;

    // if (firstDepBaggageChecked) {
    //   if (selectedBaggage.checkedBagFirst !== null) {
    //     // Extracting the numeric value from the string
    //     const baggagePrice = parseFloat(selectedBaggage.checkedBagFirst.match(/\d+/)[0]);
    //     //console.log("BaggagePrice", baggagePrice)
    //     updatedPrice += baggagePrice;
    //   } else {
    //     updatedPrice += 35;
    //   }
    // }
    //if (secondDepFirstBaggageChecked) updatedPrice += 5;
    // if (secondDepBaggageChecked) {
    //   if (selectedBaggage.checkedBagSecond !== "Kindly refer to the Airlines baggage policy") {
    //     // Extracting the numeric value from the string
    //     const baggagePrice = parseFloat(selectedBaggage.checkedBagSecond.match(/\d+/)[0]);
    //     //console.log("BaggagePriceSecond", baggagePrice)
    //     updatedPrice += baggagePrice;
    //   } else {
    //     updatedPrice += 35;
    //   }
    // }
    // if (firstRetBaggageChecked) updatedPrice += 20;
    // if (firstRetFirstBaggageChecked) updatedPrice += 20;
    // // if (secondRetBaggageChecked) updatedPrice += 20;
    // if (secondRetFirstBaggageChecked) updatedPrice += 20;

    if (firstRetFirstBaggageChecked) updatedPrice += 20;
    // if (secondDepBaggageChecked) updatedPrice += 20;
    if (secondRetFirstBaggageChecked) updatedPrice += 40;
    if (thirdRetFirstBaggageChecked) updatedPrice += 60;
    if (fourthRetFirstBaggageChecked) updatedPrice += 80;
    if (fifthRetFirstBaggageChecked) updatedPrice += 100;
    if (sixthRetFirstBaggageChecked) updatedPrice += 120;
    if (seventhRetFirstBaggageChecked) updatedPrice += 140;
    if (eirthRetFirstBaggageChecked) updatedPrice += 160;
    if (ninthRetFirstBaggageChecked) updatedPrice += 180;
    //if (firstRetFirstBaggageChecked) updatedPrice += 5;
    // if (firstRetBaggageChecked) {
    //   if (selectedBaggage.checkedBagFirst !== null) {
    //     // Extracting the numeric value from the string
    //     const baggagePrice = parseFloat(selectedBaggage.checkedBagFirst.match(/\d+/)[0]);
    //     updatedPrice += baggagePrice;
    //   } else {
    //     updatedPrice += 35;
    //   }
    // }
    // //if (secondRetFirstBaggageChecked) updatedPrice += 5;
    // if (secondRetBaggageChecked) {
    //   if (selectedBaggage.checkedBagSecond !== "Kindly refer to the Airlines baggage policy") {
    //     // Extracting the numeric value from the string
    //     const baggagePrice = parseFloat(selectedBaggage.checkedBagSecond.match(/\d+/)[0]);
    //     updatedPrice += baggagePrice;
    //   } else {
    //     updatedPrice += 35;
    //   }
    // }
    if (firstRetBaggageChecked) updatedPrice += 35;
    // if (secondDepBaggageChecked) updatedPrice += 20;
    if (secondRetBaggageChecked) updatedPrice += 70;
    if (thirdRetBaggageChecked) updatedPrice += 105;
    if (fourthRetBaggageChecked) updatedPrice += 140;
    if (fifthRetBaggageChecked) updatedPrice += 175;
    if (sixthRetBaggageChecked) updatedPrice += 210;
    if (seventhRetBaggageChecked) updatedPrice += 245;
    if (eirthRetBaggageChecked) updatedPrice += 280;

  // Calculate and add selected baggage prices for return
  if (selectedRetCheckbox === 0) {
    updatedPrice += 5.05; // First Carry-on Bag (Return)
  } else if (selectedRetCheckbox === 1) {
    updatedPrice += 5.05; // First Checked Bag (Return)
  }
  updateSelectedDepFirstCheckbox();
  updateSelectedDepCheckbox();
  updateSelectedRetCheckbox();
  updateSelectedRetFirstCheckbox();
  // Set the total price in state
  setTotalPriceDisplay(updatedPrice.toFixed(2));
};

useEffect(() => {
  updateTotalPrice();
}, [selectedCard, addToBooking, selectedProducts, flightCancel, firstDepBaggageChecked, secondDepBaggageChecked, firstDepFirstBaggageChecked, secondDepFirstBaggageChecked, updateSelectedDepCheckbox, updateSelectedDepFirstCheckbox, updateSelectedRetCheckbox, updateSelectedRetFirstCheckbox]);
////console.log(updateTotalPrice)

useEffect(() => {
  if (selectedCard !== null && !hasSelectedCardValue) {
    setHasSelectedCardValue(true);
  }

  if (addToBooking && !hasSelectedCardValueADD) {
    setHasSelectedCardValueADD(true);
  }

  if (flightCancel !== false && !hasSelectedCardValueflight) {
    setHasSelectedCardValueflight(true);
  }
}, [selectedCard, addToBooking, flightCancel]);




  const handleResetSeat = () => {
	setSelectedCard(null);
	setHasSelectedCardValue(!hasSelectedCardValue);
	setTotalPriceDisplay(totalPriceDisplay-totalPriceSeat)
}

const handleRemove = () => {
	setAddToBooking(false);
	setHasSelectedCardValueADD(!hasSelectedCardValueADD);
	setTotalPriceDisplay(totalPriceDisplay-7.99)
  }

  const handleFlightCancelSelected = () => {
	setFlightCancel(true);
  }
  const handleFlightCancelNotSelected = () => {
    if (flightCancel) {
      setTotalPriceDisplay(totalPriceDisplay - 19.99);
    }
    setFlightCancel(false);
    }

  
//const BothCheckedBag = parseFloat(selectedBaggage.checkedBagSecond.match(/\d+/)[0]) + parseFloat(selectedBaggage.checkedBagSecond.match(/\d+/)[0]);
  


//   useEffect(() => {
// 	setBookingRequest((prevState) => ({
// 	  ...prevState,
// 	  passengers: [{
// 		...prevState.passengers[0], // Update the first passenger
// 		phoneNumber: phoneNumber,
// 		email: email,
// 	  }],
// 	}));
//   }, [phoneNumber, email]);

  
// const handleWebCheckIn = () => {
// 	setWebCheckIn(true);
// }
// const productPrices = [roundedPrice, roundedTax, totalPriceSeat, 19.99, 50.00];

// const calculateTotalPrice = () => {
//     let newTotalPrice = 0;
//     for (let i = 0; i < selectedProducts.length; i++) {
//       if (selectedProducts[i]) {
//         newTotalPrice += productPrices[i];
//       }
//     }
//     setTotalPriceDisplay(newTotalPrice);
//   };
const dynamicPriceElement = selectedBaggage.checkedBagFirst;
const dynamicPriceElementSecond = selectedBaggage.checkedBagSecond;
let dynamicPrice = 0; // Default value
let dynamicPriceSecond = 0;

if (dynamicPriceElement) {
  const matchResult = dynamicPriceElement.match(/\$\s*(\d+)/);
  if (matchResult && matchResult.length > 1) {
    dynamicPrice = parseFloat(matchResult[1]);
    //console.log(dynamicPrice);
  }
  
}
if (dynamicPriceElementSecond) {
  const matchResultSecond = dynamicPriceElementSecond.match(/\$\s*(\d+)/);
  if (matchResultSecond && matchResultSecond.length > 1) {
    dynamicPriceSecond = parseFloat(matchResultSecond[1]);
    //console.log(dynamicPrice);
  }
  
}

const priceIntegerPart = Math.floor(dynamicPrice);
const priceDecimalPart = Math.round((dynamicPrice - priceIntegerPart) * 100);
const priceIntegerPartSecond = Math.floor(dynamicPriceSecond);
const priceDecimalPartSecond = Math.round((dynamicPriceSecond - priceIntegerPart) * 100);
const bothBag = priceIntegerPart + priceIntegerPartSecond;

function extractPrice(baggageInfo) {
  if (!baggageInfo) return ''; // Return an empty string if the baggageInfo is not provided

  const matchResult = baggageInfo.match(/Standard: \$\s*(\d+)/); // Match the numeric value after "Standard: $"
  if (matchResult && matchResult.length > 1) {
    return `Standard: CAD $${matchResult[1]}`; // Return the matched value
  } else {
    return ''; // Return an empty string if no match is found
  }
}

let totalBaggagePrice = 0;
if (firstDepFirstBaggageChecked) totalBaggagePrice += 20;
// if (secondDepBaggageChecked) updatedPrice += 20;
if (secondDepFirstBaggageChecked) totalBaggagePrice += 40;
if (thirdDepFirstBaggageChecked) totalBaggagePrice += 60;
if (forthDepFirstBaggageChecked) totalBaggagePrice += 80;
if (fifthDepFirstBaggageChecked) totalBaggagePrice += 100;
if (sixthDepFirstBaggageChecked) totalBaggagePrice += 120;
if (seventhDepFirstBaggageChecked) totalBaggagePrice += 140;
if (eirthDepFirstBaggageChecked) totalBaggagePrice += 160;
if (ninthDepFirstBaggageChecked) totalBaggagePrice += 180;

if (firstDepBaggageChecked) totalBaggagePrice += 35;
// if (secondDepBaggageChecked) updatedPrice += 20;
if (secondDepBaggageChecked) totalBaggagePrice += 70;
if (thirdDepBaggageChecked) totalBaggagePrice += 105;
if (fourthDepBaggageChecked) totalBaggagePrice += 140;
if (fifthDepBaggageChecked) totalBaggagePrice += 175;
if (sixthDepBaggageChecked) totalBaggagePrice += 210;
if (seventhDepBaggageChecked) totalBaggagePrice += 245;
if (eirthDepBaggageChecked) totalBaggagePrice += 280;

if (firstRetFirstBaggageChecked) totalBaggagePrice += 20;
// if (secondDepBaggageChecked) updatedPrice += 20;
if (secondRetFirstBaggageChecked) totalBaggagePrice += 40;
if (thirdRetFirstBaggageChecked) totalBaggagePrice += 60;
if (fourthRetFirstBaggageChecked) totalBaggagePrice += 80;
if (fifthRetFirstBaggageChecked) totalBaggagePrice += 100;
if (sixthRetFirstBaggageChecked) totalBaggagePrice += 120;
if (seventhRetFirstBaggageChecked) totalBaggagePrice += 140;
if (eirthRetFirstBaggageChecked) totalBaggagePrice += 160;
if (ninthRetFirstBaggageChecked) totalBaggagePrice += 180;
if (firstRetBaggageChecked) totalBaggagePrice += 35;
// if (secondDepBaggageChecked) updatedPrice += 20;
if (secondRetBaggageChecked) totalBaggagePrice += 70;
if (thirdRetBaggageChecked) totalBaggagePrice += 105;
if (fourthRetBaggageChecked) totalBaggagePrice += 140;
if (fifthRetBaggageChecked) totalBaggagePrice += 175;
if (sixthRetBaggageChecked) totalBaggagePrice += 210;
if (seventhRetBaggageChecked) totalBaggagePrice += 245;
if (eirthRetBaggageChecked) totalBaggagePrice += 280;
  return (
	<>
  <MetaTitle url={`${apiIP}/payment/`} />
    <div class="container-fluid">
			
			<div class="row">
				
			<div class="col-md-12">
				<div class="mainsummry" style={{marginTop: '75px', fontFamily: 'var(--bs-body-font-family)'}}>
				<div class="innersummry"><img src={yesMark} width="40" height="40" alt="yesMark"/> Summary & Traveler Details</div>
				<div class="linewidth"></div> 
				<div class="innersummry2">
					<img src={yesMarkBlank} width="40" height="40" alt="yesMarkBlank"/> Billing</div>					
                  <div style={{clear: 'both'}}></div>	
				</div>
				
				  <div class="payment-detail-container">				  			  
				  <div class="row wrapper-payment">
				      <div class="col-md-9">						
						<div class="marb15">         
							{/* <!--next-section--> */}
					     	<div class="payment-boxsadow">
								
							<div class="payment-detail-head">
								<div class="payment-head-depar">Departure</div>
								<div class="payment-head-Baggage">
								{/*<strong>Baggage:</strong> 2pc | */}<strong>Cabin</strong> {selectedFlight.cabinClass === 1 ? 'Economy' : selectedFlight.cabinClass === 2 ? 'Premium Economy' : selectedFlight.cabinClass === 3 ? 'Business' : selectedFlight.cabinClass === 4 ? 'First' : ''}
								{/* <p><strong>Brond Name:</strong> Basic Economy</p> */}
								</div>
							</div>
								
                            <div class="payment-detail-row">
								
								<div class="mobile-respons">
                                <div class="payment-detail-airline">
                                    <img src={`https://imgfolders.com/farehutz/${selectedFlight.outBound[0].airlineName}.png`} alt={selectedFlight.outBound[0].airlineName} />
                                    <div class="payment-airline-name-fno">
                                        <div class="payment-airlineName">{airlinesName[selectedFlight.outBound[0].airlineName]}</div>
                                        {selectedFlight.outBound[0].flightNo !== '' && selectedFlight.outBound[0].equipmentType !== '' ? (
                                        <div class="payment-flightno">Flight No. {selectedFlight.outBound[0].flightNo} | EQP-{selectedFlight.outBound[0].equipmentType} </div>
                                        ): null}
                                        {selectedFlight.outBound[0].airlineName !== selectedFlight.outBound[0].opratingAirline !== '' ? (
										<div class="payment-flightno">Operated by {airlinesName[selectedFlight.outBound[0].opratingAirline]}</div>
                    ): null}
                                    </div>
                                </div>
								</div>
									
							<div class="mobile-respons-next">		
                          <div class="payment-detail-from-to">
									
                          <div class="payment-detail-from">
                         <div class="payment-from-city"><strong>{selectedFlight.outBound[0].fromAirport}</strong> - {selectedFlight.outBound[0]?.fromAirport && airport.find(a => a.airportCode === selectedFlight.outBound[0]?.fromAirport)?.airportName}</div>
                         <div class="payment-from-time"><strong>{formatDateAndTime(selectedFlight.outBound[0]?.depDate).formattedTimeString}</strong> {formatDateAndTime(selectedFlight.outBound[0]?.depDate).formattedDateString}</div>
                        </div>
									
                                    <div class="payment-ddinfo-durastop">
                                        <div class="payment-duration">{`${convertMinutesToHoursAndMinutes(selectedFlight.outBound[0]?.estimateTime)}`} </div>
                                        <div class="payment-Detailsstop-graph">
                                        {selectedFlight.outBound[0]?.layOverTime > 0 && (
										<div class="stop-dot-paymnt">
                                                <div class="stop-tool-paymnt">
                                                    <div class="stoplay-head">
                                                       <span class="ng-binding">{`Layover: ${convertMinutesToHoursAndMinutes(selectedFlight.outBound[0]?.layOverTime)}, ${selectedFlight.outBound[0]?.toAirport}`}</span> {" "}
                                                        {/* <span>Layover Time</span> */}
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        )}    
										</div>
							         {/* <!--<div class="payment-stop">1<sup>st</sup> Layover (20h 30m)</div>--> */}
                                        </div>
									
                                    <div class="payment-detail-to">
                                        <div class="payment-from-city"><strong>{selectedFlight.outBound[2]?.toAirport ? selectedFlight.outBound[2].toAirport : selectedFlight.outBound[1]?.toAirport ? selectedFlight.outBound[1].toAirport : selectedFlight.outBound[0]?.toAirport}</strong> - {(selectedFlight.outBound[1]?.toAirport && airport.find(a => a.airportCode === selectedFlight.outBound[1]?.toAirport)?.airportName) || (selectedFlight.outBound[0]?.toAirport && airport.find(a => a.airportCode === selectedFlight.outBound[0]?.toAirport)?.airportName)}</div>
                                        <div class="payment-from-time"><strong>{selectedFlight.outBound[2]?.reachDate ? ( formatDateAndTime(selectedFlight.outBound[2]?.reachDate)?.formattedTimeString || 'No Time To Display' ) : selectedFlight.outBound[1]?.reachDate ? ( formatDateAndTime(selectedFlight.outBound[1]?.reachDate)?.formattedTimeString || 'No Time To Display' ) : selectedFlight.outBound[0]?.reachDate ? ( formatDateAndTime(selectedFlight.outBound[0]?.reachDate)?.formattedTimeString || 'No Time To Display' ) : 'No reach date available'}</strong> { formatDateAndTime(selectedFlight.outBound[0]?.depDate).formattedDateString !== (selectedFlight.outBound[1]?.reachDate ? formatDateAndTime(selectedFlight.outBound[1]?.reachDate).formattedDateString : selectedFlight.outBound[0]?.reachDate ? formatDateAndTime(selectedFlight.outBound[0]?.reachDate).formattedDateString : 'No Date To Display') ?<sup>+1</sup> : null } {selectedFlight.outBound[1]?.reachDate ? ( formatDateAndTime(selectedFlight.outBound[1]?.reachDate)?.formattedDateString || 'No Date To Display' ) : selectedFlight.outBound[0]?.reachDate ? ( formatDateAndTime(selectedFlight.outBound[0]?.reachDate)?.formattedDateString || 'No Date To Display' ) : 'No Date To Display'}</div>
                                        {
    formatDateAndTime(selectedFlight.outBound[0]?.depDate).formattedDateString !==
    (selectedFlight.outBound[2]?.reachDate
        ? formatDateAndTime(selectedFlight.outBound[2]?.reachDate).formattedDateString
        :selectedFlight.outBound[1]?.reachDate
      ? formatDateAndTime(selectedFlight.outBound[1]?.reachDate).formattedDateString
      : selectedFlight.outBound[0]?.reachDate
      ? formatDateAndTime(selectedFlight.outBound[0]?.reachDate).formattedDateString // Change this line
      : 'No Date To Display')
      ?
										<p class="pay-arrivnext">Arrivel next day</p>
                                        : null
  }
                                    </div>
									
									
                                </div>
							</div>	
								
                                
								
                            </div>
							<div class="payment-detail-footer">
								<div class="payment-detail-show open-btn">
                                  <div data-toggle="collapse" style={{color: '#f38216'}} onClick={toggleDepCollapse}>
                                            Flight Details <i class="fa fa-angle-down rotate-reset"></i>
                                        </div>
                                    </div>
								
								<div class="payment-head-Baggage">
									<p>Total Trip Duration: {`${convertMinutesToHoursAndMinutes(selectedFlight.outBound[0].estimateTime)}`}</p>
								</div>
							</div>
								
							<div class="vertical-line-flights"></div>
                            {departDetailsVisible && (	
                              <>	
                              <button class="btn btn-outline-dark btn-sm" style={{cursor: 'pointer', float: 'right', marginLeft: '5px'}} onClick={toggleClose}>Close X</button>						
							<div class="pay-showinner">	
              
							{selectedFlight?.outBound?.map((outBoundItem, index) => (	
                
							<div key={index} class="payment-detail-row">
              
								<div class="mobile-respons">
                                <div class="payment-detail-airline">
                                    <img src={`https://imgfolders.com/farehutz/${outBoundItem.airlineName}.png`} alt={outBoundItem.airlineName} />
                                    <div class="payment-airline-name-fno">
                                        <div class="payment-airlineName">{airlinesName[outBoundItem.airlineName]}</div>
                                        {outBoundItem.flightNo !== '' && outBoundItem.equipmentType !== '' ? (
                                        <div class="payment-flightno">Flight No. {outBoundItem.flightNo} | EQP-{outBoundItem.equipmentType} </div>
                                        ): null}
                                        {outBoundItem.airlineName !== outBoundItem.opratingAirline ? (
										<div class="payment-flightno">Operated by {airlinesName[outBoundItem.opratingAirline]}</div>
                                        ): null }
                                    </div>
                                </div>
								</div>	
									
							<div class="mobile-respons-next">	
                          <div class="payment-detail-from-to">									
                                    <div class="payment-detail-from">
                         <div class="payment-from-city"><strong>{outBoundItem.fromAirport}</strong> - {outBoundItem?.fromAirport && airport.find(a => a.airportCode === outBoundItem?.fromAirport)?.airportName}</div>
                         <div class="payment-from-time"><strong>{formatDateAndTime(outBoundItem.depDate).formattedTimeString}</strong> {formatDateAndTime(outBoundItem.depDate).formattedDateString}</div>
							  {/* <p class="pay-arrivnext">Airport Changes</p> */}
                        </div>									
                                    <div class="payment-ddinfo-durastop">
                                      {outBoundItem.eft ? (
                                        <div class="payment-duration">{`${convertMinutesToHoursAndMinutes(outBoundItem.eft)}`} </div>
                                        ): null}
                                        <div class="payment-Detailsstop-graph"></div>
							          {/* <!--<div class="payment-stop">1<sup>st</sup> Layover (20h 30m)</div>--> */}
                                        </div>									
                                    <div class="payment-detail-to">
                                        <div class="payment-from-city"><strong>{outBoundItem.toAirport}</strong> - {outBoundItem?.toAirport && airport.find(a => a.airportCode === outBoundItem?.toAirport)?.airportName}</div>
                                        <div class="payment-from-time"><strong>{formatDateAndTime(outBoundItem.reachDate).formattedTimeString}</strong> {formatDateAndTime(outBoundItem.reachDate).formattedDateString}</div>
										{
                                  formatDateAndTime(outBoundItem.depDate).formattedDateString !==
                                  formatDateAndTime(outBoundItem?.reachDate).formattedDateString
                                    
                                    ? <p class="pay-arrivnext">Arrivel next day</p>
                                    : null
                                }
                                    </div>
                                </div>
                            {outBoundItem.layOverTime > 0 ? (	
							<h2 class="layover-divider">
                            	<span><i class="fa fa-clock"></i> ({convertMinutesToHoursAndMinutes(outBoundItem.layOverTime)}) At {outBoundItem?.toAirport && airport.find(a => a.airportCode === outBoundItem?.toAirport)?.cityName} </span>
                            </h2>
							) : null}	
                             </div>   
                            </div>
								))}
							
							<div class="payment-detail-footer">								
								<div class="payment-head-Baggage">
									<p>Total Trip Duration: {`${convertMinutesToHoursAndMinutes(selectedFlight.outBound[0].estimateTime)}`}</p>
								</div>
							</div>
								
						  	
							</div>
              </> )}
						    <div class="vertical-line-flights"></div>			
								
                        </div>
							{/* <!--next-section close-here-->							
							<!--next itnery--> */}
              {selectedFlight.inBound && selectedFlight.inBound[0] ? (
							<div class="payment-boxsadow">
							<div class="payment-detail-head">
								<div class="payment-head-depar">Return</div>
								<div class="payment-head-Baggage">
								{/*<strong>Baggage:</strong> 2pc | */}<strong>Cabin</strong> {selectedFlight.cabinClass === 1 ? 'Economy' : selectedFlight.cabinClass === 2 ? 'Premium Economy' : selectedFlight.cabinClass === 3 ? 'Business' : selectedFlight.cabinClass === 4 ? 'First' : ''}
								{/* <p><strong>Brond Name:</strong> Basic Economy</p> */}
								</div>
							</div>
								
                            <div class="payment-detail-row">
								<div class="mobile-respons">
                                <div class="payment-detail-airline">
                                    <img src={`https://imgfolders.com/farehutz/${selectedFlight.inBound[0].airlineName}.png`} alt={selectedFlight.inBound[0].airlineName} />
                                    <div class="payment-airline-name-fno">
                                        <div class="payment-airlineName">{airlinesName[selectedFlight.inBound[0].airlineName]}</div>
                                        {selectedFlight.inBound[0].flightNo !== '' && selectedFlight.inBound[0].equipmentType !== '' ? (
                                        <div class="payment-flightno">Flight No. {selectedFlight.inBound[0].flightNo} | EQP-{selectedFlight.inBound[0].equipmentType} </div>
                                        ): null}
                                        {selectedFlight.inBound[0].airlineName !== selectedFlight.inBound[0].opratingAirline ? (
										<div class="payment-flightno">Operated by {airlinesName[selectedFlight.inBound[0].opratingAirline]}</div>
                                        ): null}
                                    </div>
                                </div>
								</div>
							
								<div class="mobile-respons-next">
                          <div class="payment-detail-from-to">
									
                          <div class="payment-detail-from">
                         <div class="payment-from-city"><strong>{selectedFlight.inBound[0].fromAirport}</strong> - {selectedFlight.inBound[0]?.fromAirport && airport.find(a => a.airportCode === selectedFlight.inBound[0]?.fromAirport)?.airportName}</div>
                         <div class="payment-from-time"><strong>{formatDateAndTime(selectedFlight.inBound[0].depDate).formattedTimeString}</strong> {formatDateAndTime(selectedFlight.inBound[0]?.depDate).formattedDateString}</div>
                        </div>
									
                                    <div class="payment-ddinfo-durastop">
                                        <div class="payment-duration">{`${convertMinutesToHoursAndMinutes(selectedFlight.inBound[0].estimateTime)}`} </div>
                                        <div class="payment-Detailsstop-graph">
                                        {selectedFlight.inBound[0].layOverTime > 0 && (
										<div class="stop-dot-paymnt">
                                                <div class="stop-tool-paymnt">
                                                    <div class="stoplay-head">
                                                       <span class="ng-binding">{`Layover: ${convertMinutesToHoursAndMinutes(selectedFlight.inBound[0].layOverTime)}, ${selectedFlight.inBound[0].toAirport}`}</span>{" "}
                                                        {/* <span>Layover Time</span> */}
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        )}
										</div>
							         {/* <!--<div class="payment-stop">1<sup>st</sup> Layover (20h 30m)</div>--> */}
                                        </div>
									
                                    <div class="payment-detail-to">
                                        <div class="payment-from-city"><strong>{selectedFlight.inBound[2]?.toAirport ? selectedFlight.inBound[2].toAirport : selectedFlight.inBound[1]?.toAirport ? selectedFlight.inBound[1].toAirport : selectedFlight.inBound[0]?.toAirport}</strong> - {(selectedFlight.inBound[1]?.toAirport && airport.find(a => a.airportCode === selectedFlight.inBound[1]?.toAirport)?.airportName) || (selectedFlight.inBound[0]?.toAirport && airport.find(a => a.airportCode === selectedFlight.inBound[0]?.toAirport)?.airportName)}</div>
                                        <div class="payment-from-time"><strong>{selectedFlight.inBound[2]?.reachDate ? ( formatDateAndTime(selectedFlight.inBound[2]?.reachDate)?.formattedTimeString || 'No Time To Display' ) : selectedFlight.inBound[1]?.reachDate ? ( formatDateAndTime(selectedFlight.inBound[1]?.reachDate)?.formattedTimeString || 'No Time To Display' ) : selectedFlight.inBound[0]?.reachDate ? ( formatDateAndTime(selectedFlight.inBound[0]?.reachDate)?.formattedTimeString || 'No Time To Display' ) : 'No reach date available'}</strong> { formatDateAndTime(selectedFlight.inBound[0]?.depDate).formattedDateString !== (selectedFlight.inBound[1]?.reachDate ? formatDateAndTime(selectedFlight.inBound[1]?.reachDate).formattedDateString : selectedFlight.inBound[0]?.reachDate ? formatDateAndTime(selectedFlight.inBound[0]?.reachDate).formattedDateString  : 'No Date To Display') ?<sup>+1</sup>: null } {selectedFlight.inBound[1]?.reachDate ? ( formatDateAndTime(selectedFlight.inBound[1]?.reachDate)?.formattedDateString || 'No Date To Display' ) : selectedFlight.inBound[0]?.reachDate ? ( formatDateAndTime(selectedFlight.inBound[0]?.reachDate)?.formattedDateString || 'No Date To Display' ) : 'No Date To Display'}</div>
										{
    formatDateAndTime(selectedFlight.inBound[0]?.depDate).formattedDateString !==
    (selectedFlight.inBound[2]?.reachDate
        ? formatDateAndTime(selectedFlight.inBound[2]?.reachDate).formattedDateString
        :selectedFlight.inBound[1]?.reachDate
      ? formatDateAndTime(selectedFlight.inBound[1]?.reachDate).formattedDateString
      : selectedFlight.inBound[0]?.reachDate
      ? formatDateAndTime(selectedFlight.inBound[0]?.reachDate).formattedDateString // Change this line
      : 'No Date To Display')
      ? <p class="pay-arrivnext">Arrivel next day</p>
      : null
  }
                                    </div>
									
									
                                </div>
							</div>
								
                            </div>							
							<div class="payment-detail-footer">
								<div class="payment-detail-show open-btn">
                                  <div data-toggle="collapse" style={{color: '#f38216'}} onClick={toggleRetCollapse}>
                                            Flight Details <i class="fa fa-angle-down rotate-reset"></i>
                                        </div>
                                    </div>
								
								<div class="payment-head-Baggage">
									<p>Total Trip Duration: {`${convertMinutesToHoursAndMinutes(selectedFlight.inBound[0].estimateTime)}`}</p>
								</div>
							</div>
							
							<div class="vertical-line-flights"></div>
                            {returnDetailsVisible && (
                              <>
                              <button class="btn btn-outline-dark btn-sm" style={{cursor: 'pointer', float: 'right', marginLeft: '5px'}} onClick={toggleClose}>Close X</button>								
							<div class="pay-showinner">	
              
								{selectedFlight?.inBound?.map((inBoundItem, index) => (
							<div key={index} class="payment-detail-row">
              
								<div class="mobile-respons">
                                <div class="payment-detail-airline">
                                    <img src={`https://imgfolders.com/farehutz/${inBoundItem.airlineName}.png`} alt={inBoundItem.airlineName} />
                                    <div class="payment-airline-name-fno">
                                        <div class="payment-airlineName">{airlinesName[inBoundItem.airlineName]}</div>
                                        {inBoundItem.flightNo !== '' && inBoundItem.equipmentType !== '' ? (
                                        <div class="payment-flightno">Flight No. {inBoundItem.flightNo} | EQP-{inBoundItem.equipmentType} </div>
                                        ): null}
                                        {inBoundItem.airlineName !== inBoundItem.opratingAirline ? (
										<div class="payment-flightno">Operated by {airlinesName[inBoundItem.opratingAirline]}</div>
                                        ): null}
                                    </div>
                                </div>
									</div>
							<div class="mobile-respons-next">	
								
                          <div class="payment-detail-from-to">
									
                                   <div class="payment-detail-from">
                         <div class="payment-from-city"><strong>{inBoundItem.fromAirport}</strong> - {inBoundItem?.fromAirport && airport.find(a => a.airportCode === inBoundItem?.fromAirport)?.airportName}</div>
                         <div class="payment-from-time"><strong>{formatDateAndTime(inBoundItem.depDate).formattedTimeString}</strong> {formatDateAndTime(inBoundItem?.depDate).formattedDateString}</div>
							  {/* <p class="pay-arrivnext">Airport Changes</p> */}
                        </div>
									
                                    <div class="payment-ddinfo-durastop">
                                        <div class="payment-duration">{`${convertMinutesToHoursAndMinutes(inBoundItem.eft)}`} </div>
                                        <div class="payment-Detailsstop-graph"></div>
							         {/* <!--<div class="payment-stop">1<sup>st</sup> Layover (20h 30m)</div>--> */}
                                        </div>
									
                                    <div class="payment-detail-to">
                                        <div class="payment-from-city"><strong>{inBoundItem?.toAirport}</strong> - {inBoundItem?.toAirport && airport.find(a => a.airportCode === inBoundItem?.toAirport)?.airportName}</div>
                                        <div class="payment-from-time"><strong>{formatDateAndTime(inBoundItem?.reachDate).formattedTimeString}</strong> {formatDateAndTime(inBoundItem?.reachDate).formattedDateString}</div>
										{
                                  formatDateAndTime(inBoundItem?.depDate).formattedDateString !==
                                  formatDateAndTime(inBoundItem?.reachDate).formattedDateString
                                    
                                    ? <p class="pay-arrivnext">Arrivel next day</p>
                                    : null
                                }
                                    </div>
									
									
                                </div>
                                {inBoundItem.layOverTime > 0 ? (
								<h2 class="layover-divider">
                            	<span><i class="fa fa-clock"></i>({`${convertMinutesToHoursAndMinutes(inBoundItem.layOverTime)}`}) At {inBoundItem?.toAirport && airport.find(a => a.airportCode === inBoundItem?.toAirport)?.cityName} </span>
                            </h2>
                                ): null}
								
                            </div>    
                            </div>	
                            ))}
                            
							<div class="payment-detail-footer">								
								<div class="payment-head-Baggage">
									<p>Total Trip Duration: {`${convertMinutesToHoursAndMinutes(selectedFlight.inBound[0].estimateTime)}`}</p>
								</div>
							</div>
								
						  	
							</div>
              </>
                            )}
							
                        </div> 
              ) : null }
				    		{/* <!--next-section close-here--> */}
							
                        </div>
						
						
						{/* <div class="marb15">
						<div class="toptitle">
							<h5><img src={userIcon} alt=""/> Select Upgrade</h5>
							</div>	
					     <div class="payment-boxsadow">
						<div style={{fontSize: '12px'}}>Selecting Upgrade Help you more flexibility and comfort.</div>	 
							 
						<div class="row">	 
						<div class="col-md-3 col-sm-6 m-top">
						<div class="BE-title">Basic Economy</div>	
						<div class="BE-show-cabin">
						<div class="BE-show-cabininr-bold"> 
								<div>Cabin</div>
								<div>Economy</div>
								</div>
							<div class="BE-show-cabininr-bold"> 
								<div>Baggage</div>
								<div>2</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Carry-on</div>
								<div>1</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Cheacked bag</div>
								<div>1</div>
								</div>
							
							<div class="BE-show-cabininr-bold"> 
								<div>Price</div>
								<div>$750</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Trip</div>
								<div>Round Trip</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Traveler(s)</div>
								<div>3</div>
								</div>							
							<button type="button" class="activated">Selected</button>							
						</div>											
						
						</div>
						<div class="col-md-3 col-sm-6 m-top">
						<div class="BE-title">Main Cabin</div>	
						<div class="BE-show-cabin">
						<div class="BE-show-cabininr-bold"> 
								<div>Cabin</div>
								<div>Economy</div>
								</div>
							<div class="BE-show-cabininr-bold"> 
								<div>Baggage</div>
								<div>2</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Carry-on</div>
								<div>1</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Cheacked bag</div>
								<div>1</div>
								</div>
							
							<div class="BE-show-cabininr-bold"> 
								<div>Price</div>
								<div>$750</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Trip</div>
								<div>Round Trip</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Traveler(s)</div>
								<div>3</div>
								</div>							
							<button type="button">Select</button>							
						</div>											
						
						</div>
						<div class="col-md-3 col-sm-6 m-top">
						<div class="BE-title">Premium Economy</div>	
						<div class="BE-show-cabin">
						<div class="BE-show-cabininr-bold"> 
								<div>Cabin</div>
								<div>Economy</div>
								</div>
							<div class="BE-show-cabininr-bold"> 
								<div>Baggage</div>
								<div>2</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Carry-on</div>
								<div>1</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Cheacked bag</div>
								<div>1</div>
								</div>
							
							<div class="BE-show-cabininr-bold"> 
								<div>Price</div>
								<div>$750</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Trip</div>
								<div>Round Trip</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Traveler(s)</div>
								<div>3</div>
								</div>							
							<button type="button">Select</button>							
						</div>											
						
						</div>
						<div class="col-md-3 col-sm-6 m-top">
						<div class="BE-title">Economy Flex</div>	
						<div class="BE-show-cabin">
						<div class="BE-show-cabininr-bold"> 
								<div>Cabin</div>
								<div>Economy</div>
								</div>
							<div class="BE-show-cabininr-bold"> 
								<div>Baggage</div>
								<div>2</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Carry-on</div>
								<div>1</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Cheacked bag</div>
								<div>1</div>
								</div>
							
							<div class="BE-show-cabininr-bold"> 
								<div>Price</div>
								<div>$750</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Trip</div>
								<div>Round Trip</div>
								</div>
							<div class="BE-show-cabininr-think"> 
								<div>Traveler(s)</div>
								<div>3</div>
								</div>							
							<button type="button">Select</button>							
						</div>											
						
						</div>
							
							
						</div>							 
							 
					    <div style={{fontSize: '12px'}}>* There might be Additional fees that may apply as per Airlines policies.</div>
							 

				        </div>						
			            </div> */}
						
						
						<div class="marb15">
						<div class="toptitle">
							<h5><img src={userIcon} alt="userIcon"/> Contact Details</h5>
							</div>	
					     <div class="payment-boxsadow">
						<div class="row">	 
            <form id='mailer'>
						<div class="col-md-6 traveler_title m-top">
						<h4>Mobile Number <span>*</span></h4>											
						{/* <input placeholder="Phone Number" value={phoneNumber} ref={phoneNumberRef}  onChange={(e) => {const input = e.target.value;
  const filteredInput = input.replace(/[^0-9]/g, ''); 
  setPhoneNumber(filteredInput.slice(0, 10));}} onBlur={handleBlur} id="phoneNumber" name='MobileNo' class="form-control" type="text" required /> */}
						{/* {errors.phoneNumber && <span>{errors.phoneNumber}</span>} */}
            <input
  placeholder="Phone Number"
  value={phoneNumber}
  ref={phoneNumberRef}
  onChange={(e) => {
    const input = e.target.value;
    const filteredInput = input.replace(/[^0-9]/g, '');
    setPhoneNumber(filteredInput.slice(0, 10));
  }}
  onBlur={handleBlur}
  id="phoneNumber"
  name="MobileNo"
  className="form-control"
  type="text"
  required
/>
						</div>
							
						<div class="col-md-6 traveler_title m-top">							
						<h4>Email ID <span>*</span></h4>
						<input placeholder="Email ID" ref={emailRef} value={email} onChange={(e) => {setEmail(e.target.value);}} onBlur={handleBlur} id="email" name='EMAIL' class="form-control" type="text" required />
						{/* {errors.email && <span>{errors.email}</span>} */}
						</div>
							
            </form>
						</div>	
							 

				        </div>						
			            </div>
						
						<div class="marb15">
							<div class="CP_boxsadow">						
						 <h5 class="mar-b">
							 <img src={SeatRed} alt="SeatRed"/> Select Seat 
							 <span>CAD $7.<sup>99</sup> <span class="per-p"> (Per Trip / Passsenger)</span>
							 </span> 
							 </h5>
							</div>							
							<div class="payment-boxsadow">
								{selectedCard !== null ? (
								<div class="seat-reset">
								<button type="button" onClick={handleResetSeat}>Reset</button>
								</div>
								) : null}
							<div class="seatmainbox">
															
								<div class={`solid_br seatboxw ${selectedCard === 0 ? "selected" : ""}`} onClick={() => {handleCardClick(0);}}>
								<div class={`window_btn_D ${selectedCard === 0 ? "selected" : ""}`} onClick={updateTotalPrice}>Window Seat</div>
								<div class="seating">		
					            <img src={Awindow} alt="Awindow"/>
								</div>
								</div>
								
								
								<div class={`solid_br seatboxw ${selectedCard === 1 ? "selected" : ""}`} onClick={() => {handleCardClick(1); updateTotalPrice()}}>
								<div class={`window_btn_D ${selectedCard === 1 ? "selected" : ""}`} >
									Middle Seat</div>
								<div class="seating">		
					           <img src={Middle} alt="Middle"/>
								</div>
								</div>
								
								
								<div class={`solid_br seatboxw ${selectedCard === 2 ? "selected" : ""}`} onClick={() => {handleCardClick(2); updateTotalPrice()}}>
								<div class={`window_btn_D ${selectedCard === 2 ? "selected" : ""}`} >
								Aisle Seat</div>
								<div class="seating">		
					           <img src={Aisle} alt="Aisle"/>
								</div>
								</div>
								
								</div>
								
							<div class="row">
							<div class="col-md-1 discseat">Disclaimer&nbsp;:</div>
							<div class="col-md-11 seat-disc"> Seat assignments at the sole desecration of the Airlines. Selected preference will be passed to the Airlines additionally this includes non-refundable service fees.</div>
							</div>
								
							</div>
						</div>
						  
						  <div class="marb15">
							<div class="CP_boxsadow">						
						 <h5 class="mar-b">
							 <img src={BagIcon} alt="BagIcon"/> Baggage Details 
							 {/* <span>$10.<sup>10</sup> <span class="per-p"> (Entire Trip)</span> </span>  */}
							 
							 </h5>
							</div>
							
							<div class="payment-boxsadow">
								<div class="row">
								<div class="col-md-6" style={{textAlign: 'center'}}>
									<div style={{padding: '8px'}}><img src={bagIcon2} alt="bagIcon2" /> Departure Baggage ({selectedFlight.outBound[0].fromAirport}-{selectedFlight.outBound[1]?.toAirport ? selectedFlight.outBound[1].toAirport : selectedFlight.outBound[0]?.toAirport})</div>
									
									<label class="paybage_label_with">
								<div class={`paybage_solid_br ${selectedDepCheckbox === 0 ? "selected" : ""}`}>
								<img src={CarryOn} alt="CarryOn"/>
								<div class="fon13">Carry-on Bag</div>
								<div class="seat_icon">		
                {selectedBaggage.carryOnBag !== "NO" ? (
					             <div class="bagga_price" ><img src={Included} alt=''></img></div>
                      ) : (
                        <>
                      {/* <div class="bagga_price" >First Bag $20</div> <input type="checkbox" checked={firstDepFirstBaggageChecked} onChange={() => { setFirstDepFirstBaggageChecked(!firstDepFirstBaggageChecked); updateTotalPrice();}} /> */}
                      <div class="custom_select pointer">   
                      {selectedDepFirstCheckbox === null ? (                           
		<div class="select_button text-left" id="outAddBag" onClick={() => {setDropdownOpen(!dropdownOpen)}}>Add Carry-on <i class="bi bi-chevron-down down_arrow"></i></div>
    ) : ( <div class="mb10 text-center" id="outBagAddedTxt"><div class="removebaggage" data-name="out" style={{color: 'blue', fontWeight: '500', cursor: 'pointer'}} onClick={() => {setSelectedDepFirstCheckbox(null); updateSelectedDepFirstCheckbox(); setFirstDepFirstBaggageChecked(false); setSecondDepFirstBaggageChecked(false); setThirdDepFirstBaggageChecked(false); setForthDepFirstBaggageChecked(false); setFifthDepFirstBaggageChecked(false); setSixthDepFirstBaggageChecked(false); setSeventhDepFirstBaggageChecked(false); setEirthDepFirstBaggageChecked(false); setNinthDepFirstBaggageChecked(false); updateTotalPrice();}}>Remove</div>{selectedDepFirstCheckbox} Carry-on Bag.</div>)}
		{dropdownOpen === true ? (
    <ul class="custom_drop custom_drop2" /*style={{display: 'block' }}*/ id="outulli">
        {[...Array(totalPassengers)].map((_, index) => (
            <li key={index} onClick={() => { 
                setFirstDepFirstBaggageChecked(index === 0);
                setSecondDepFirstBaggageChecked(index === 1);
                setThirdDepFirstBaggageChecked(index === 2);
                setForthDepFirstBaggageChecked(index === 3);
                setFifthDepFirstBaggageChecked(index === 4);
                setSixthDepFirstBaggageChecked(index === 5);
                setSeventhDepFirstBaggageChecked(index === 6);
                setEirthDepFirstBaggageChecked(index === 7);
                setNinthDepFirstBaggageChecked(index === 8);
                updateTotalPrice(); 
                setDropdownOpen(!dropdownOpen)
            }}>
                <div>{index + 1} Carry-on Bag.</div> <span>CAD ${(index + 1) * 20}</span>
            </li>
        ))}
    </ul>
) : null}

		</div>	
                        {/* <select class="form-control form-select gender" value='' name=''>
                              <option value="0 Carry-on baggage">Add Baggage</option>
                              <option value="1 Carry-on baggage">1 Carry-on baggage<span class="" style={{justifyContent: 'both'}}>              $35</span></option>
                              <option value="2 Carry-on baggage">2 Carry-on baggage<span class="per-p"> $70</span></option>
                              <option value="3 Carry-on baggage">3 Carry-on baggage<span class="per-p"> $105</span></option>
                              <option value="4 Carry-on baggage">4 Carry-on baggage<span class="per-p"> $140</span></option>
                              <option value="5 Carry-on baggage">5 Carry-on baggage<span class="per-p"> $175</span></option>
                              <option value="6 Carry-on baggage">6 Carry-on baggage<span class="per-p"> $210</span></option>
                              <option value="7 Carry-on baggage">7 Carry-on baggage<span class="per-p"> $245</span></option>
                              <option value="8 Carry-on baggage">8 Carry-on baggage<span class="per-p"> $280</span></option>
                        </select> */}
                     
                      </>
                      )}
																	
								</div>	
								<div class="seat_icon">		
                {selectedBaggage.carryOnBag !== "NO" ? (
					            <div class="bagga_price" ></div>
                      ) : (
                        <>
                      {/* <div class="bagga_price" >Second Bag $20</div>
                      <input type="checkbox" checked={secondDepFirstBaggageChecked} onChange={() => {setSecondDepFirstBaggageChecked(!secondDepFirstBaggageChecked); updateTotalPrice();}} /> */}
                      </>
                      )}
																	
								</div>
								</div>
								</label>
								
								<label class="paybage_label_with">
								<div class={`paybage_solid_br ${selectedDepCheckbox === 1 ? "selected" : ""}`}>
								<img src={bagIcon2} alt="bagIcon2"/>
								<div class="fon13">Checked Bag</div>
								<div class="seat_icon">		
                {selectedBaggage.checkedBagFirst !== null ? (
                  <>
					            {/* <div class="bagga_price">First {extractPrice(selectedBaggage.checkedBagFirst)}</div>
								<input type="checkbox" checked={firstDepBaggageChecked} onChange={() => { setFirstDepBaggageChecked(!firstDepBaggageChecked); updateTotalPrice();}} /> */}
                <div class="custom_select pointer">
                {selectedDepCheckbox === null ? (
                <div class="select_button text-left" id="outAddBag" onClick={() => {setDropdownOpenCheckedBag(!dropdownOpenCheckedBag)}}>Add Baggage <i class="bi bi-chevron-down down_arrow"></i></div>
                ) : (
                  <div class="mb10 text-center" id="outBagAddedTxt"><div class="removebaggage" data-name="out" style={{color: 'blue', fontWeight: '500', cursor: 'pointer'}} onClick={() => {setSelectedDepCheckbox(null); updateSelectedDepCheckbox(); setFirstDepBaggageChecked(false); setSecondDepBaggageChecked(false); setThirdDepBaggageChecked(false); setFourthDepBaggageChecked(false); setFifthDepBaggageChecked(false); setSixthDepBaggageChecked(false); setSeventhDepBaggageChecked(false); setEirthDepBaggageChecked(false); updateTotalPrice();}}>Remove</div>{selectedDepCheckbox} Checked Bag.</div>
                )}
                {dropdownOpenCheckedBag === true ? (
    <ul class="custom_drop custom_drop2" /*style={{display: 'block' }}*/ id="outulli">
        {[...Array(Math.min(totalPassengers * 2, 8))].map((_, index) => (
            <li key={index} onClick={() => { 
                setFirstDepBaggageChecked(index === 0);
                setSecondDepBaggageChecked(index === 1);
                setThirdDepBaggageChecked(index === 2);
                setFourthDepBaggageChecked(index === 3);
                setFifthDepBaggageChecked(index === 4);
                setSixthDepBaggageChecked(index === 5);
                setSeventhDepBaggageChecked(index === 6);
                setEirthDepBaggageChecked(index === 7);
                updateTotalPrice(); 
                setDropdownOpenCheckedBag(!dropdownOpenCheckedBag)
            }}>
                <div>{index + 1} Checked Bag.</div> <span>CAD ${(index + 1) * 35}</span>
            </li>
        ))}
    </ul>
) : null}
</div>

                </>
                ) : (
                  <>
					            {/* <div class="bagga_price">First Bag $35</div>
								<input type="checkbox" checked={firstDepBaggageChecked} onChange={() => { setFirstDepBaggageChecked(!firstDepBaggageChecked); updateTotalPrice();}} /> */}
                <div class="custom_select pointer">
                {selectedDepCheckbox === null ? (
                <div class="select_button text-left" id="outAddBag" onClick={() => {setDropdownOpenCheckedBag(!dropdownOpenCheckedBag)}}>Add Baggage <i class="bi bi-chevron-down down_arrow"></i></div>
                ) : (
                  <div class="mb10 text-center" id="outBagAddedTxt"><div class="removebaggage" data-name="out" style={{color: 'blue', fontWeight: '500', cursor: 'pointer'}} onClick={() => {setSelectedDepCheckbox(null); updateSelectedDepCheckbox(); setFirstDepBaggageChecked(false); setSecondDepBaggageChecked(false); setThirdDepBaggageChecked(false); setFourthDepBaggageChecked(false); setFifthDepBaggageChecked(false); setSixthDepBaggageChecked(false); setSeventhDepBaggageChecked(false); setEirthDepBaggageChecked(false); updateTotalPrice();}}>Remove</div>{selectedDepCheckbox} Checked Bag.</div>
                )}
                {dropdownOpenCheckedBag === true ? (
    <ul class="custom_drop custom_drop2" /*style={{display: 'block' }}*/ id="outulli">
        {[...Array(Math.min(totalPassengers * 2, 8))].map((_, index) => (
            <li key={index} onClick={() => { 
                setFirstDepBaggageChecked(index === 0);
                setSecondDepBaggageChecked(index === 1);
                setThirdDepBaggageChecked(index === 2);
                setFourthDepBaggageChecked(index === 3);
                setFifthDepBaggageChecked(index === 4);
                setSixthDepBaggageChecked(index === 5);
                setSeventhDepBaggageChecked(index === 6);
                setEirthDepBaggageChecked(index === 7);
                updateTotalPrice(); 
                setDropdownOpenCheckedBag(!dropdownOpenCheckedBag)
            }}>
                <div>{index + 1} Checked Bag.</div> <span>CAD ${(index + 1) * 35}</span>
            </li>
        ))}
    </ul>
) : null}

</div>
                </>
                )}									
								</div>	
								<div class="seat_icon">	
                {selectedBaggage.checkedBagSecond !== "Kindly refer to the Airlines baggage policy" ? (	
                  <>
					            {/* <div class="bagga_price">Second {extractPrice(selectedBaggage.checkedBagSecond)}</div>
								<input type="checkbox" checked={secondDepBaggageChecked} onChange={() => {setSecondDepBaggageChecked(!secondDepBaggageChecked); updateTotalPrice();}} />	 */}
                </>
                ) : (
                  <>
					            {/* <div class="bagga_price">Second Bag $35</div>
								<input type="checkbox" checked={secondDepBaggageChecked} onChange={() => {setSecondDepBaggageChecked(!secondDepBaggageChecked); updateTotalPrice();}} />	 */}
                </>
                )}								
								</div>
								</div>
								</label>
									
									</div>
                  {selectedFlight.inBound && selectedFlight.inBound[0] ? (
									<div class="col-md-6" style={{textAlign: 'center'}}>
									<div style={{padding: '8px'}}><img src={bagIcon2} alt="bagIcon2" /> Return Baggage ({selectedFlight.inBound[0].fromAirport}-{selectedFlight.inBound[1]?.toAirport ? selectedFlight.inBound[1].toAirport : selectedFlight.inBound[0]?.toAirport})</div>
									
								<label class="paybage_label_with">
								<div class={`paybage_solid_br ${selectedRetCheckbox === 0 ? "selected" : ""}`}>
								<img src={CarryOn} alt="CarryOn"/>
								<div class="fon13">Carry-on Bag</div>
								<div class="seat_icon">		
                {selectedBaggage.carryOnBag !== "NO" ? (
					            <div class="bagga_price"><img src={Included} alt=''></img></div>
                ) : (
                  <>
                  {/* <div class="bagga_price">First Bag $20</div>
                  <input type="checkbox" checked={firstRetFirstBaggageChecked} onChange={() => { setFirstRetFirstBaggageChecked(!firstRetFirstBaggageChecked); updateTotalPrice();}} />	 */}
                  <div class="custom_select pointer">
                  {selectedRetFirstCheckbox === null ? (                           
		<div class="select_button text-left" id="outAddBag" onClick={() => {setDropdownOpenReturn(!dropdownOpenReturn)}}>Add Carry-on <i class="bi bi-chevron-down down_arrow"></i></div>
    ) : ( <div class="mb10 text-center" id="outBagAddedTxt"><div class="removebaggage" data-name="out" style={{color: 'blue', fontWeight: '500', cursor: 'pointer'}} onClick={() => {setSelectedRetFirstCheckbox(null); updateSelectedRetFirstCheckbox(); setFirstRetFirstBaggageChecked(false); setSecondRetFirstBaggageChecked(false); setThirdRetFirstBaggageChecked(false); setFourthRetFirstBaggageChecked(false); setFifthRetFirstBaggageChecked(false); setSixthRetFirstBaggageChecked(false); setSeventhRetFirstBaggageChecked(false); setEirthRetFirstBaggageChecked(false); setNinthRetFirstBaggageChecked(false); updateTotalPrice();}}>Remove</div>{selectedRetFirstCheckbox} Carry-on Bag.</div>)}
		{dropdownOpenReturn === true ? (
    <ul class="custom_drop custom_drop2" /*style={{display: 'block' }}*/ id="outulli">
        {[...Array(totalPassengers)].map((_, index) => (
            <li key={index} onClick={() => { 
                setFirstRetFirstBaggageChecked(index === 0);
                setSecondRetFirstBaggageChecked(index === 1);
                setThirdRetFirstBaggageChecked(index === 2);
                setFourthRetFirstBaggageChecked(index === 3);
                setFifthRetFirstBaggageChecked(index === 4);
                setSixthRetFirstBaggageChecked(index === 5);
                setSeventhRetFirstBaggageChecked(index === 6);
                setEirthRetFirstBaggageChecked(index === 7);
                setNinthRetFirstBaggageChecked(index === 8);
                updateTotalPrice(); 
                setDropdownOpenReturn(!dropdownOpenReturn)
            }}>
                <div>{index + 1} Carry-on Bag.</div> <span>CAD ${(index + 1) * 20}</span>
            </li>
        ))}
    </ul>
) : null}
</div>
                  </>
                )}
								

								</div>	
								<div class="seat_icon">	
                {selectedBaggage.carryOnBag !== "NO" ? (	
					            <div class="bagga_price"></div>
                ) : (
                  <>
                  {/* <div class="bagga_price">Second Bag $20</div>
                  <input type="checkbox" checked={secondRetFirstBaggageChecked} onChange={() => {setSecondRetFirstBaggageChecked(!secondRetFirstBaggageChecked); updateTotalPrice();}} /> */}
                  </>
                )}
																	
								</div>
								</div>
								</label>
								
								<label class="paybage_label_with">
								<div class={`paybage_solid_br ${selectedRetCheckbox === 1 ? "selected" : ""}`}>
								<img src={bagIcon2} alt="bagIcon2"/>
								<div class="fon13">Checked Bag</div>
								<div class="seat_icon">		
                {selectedBaggage.checkedBagFirst !== null ? (
                  <>
					            {/* <div class="bagga_price">First {extractPrice(selectedBaggage.checkedBagFirst)}</div>
								<input type="checkbox" checked={firstRetBaggageChecked} onChange={() => { setFirstRetBaggageChecked(!firstRetBaggageChecked); updateTotalPrice();}} />	 */}
                <div class="custom_select pointer">
                {selectedRetCheckbox === null ? (
                <div class="select_button text-left" id="outAddBag" onClick={() => {setDropdownOpenCheckedBagReturn(!dropdownOpenCheckedBagReturn)}}>Add Baggage <i class="bi bi-chevron-down down_arrow"></i></div>
                ) : (
                  <div class="mb10 text-center" id="outBagAddedTxt"><div class="removebaggage" data-name="out" style={{color: 'blue', fontWeight: '500', cursor: 'pointer'}} onClick={() => {setSelectedRetCheckbox(null); updateSelectedRetCheckbox(); setFirstRetBaggageChecked(false); setSecondRetBaggageChecked(false); setThirdRetBaggageChecked(false); setFourthRetBaggageChecked(false); setFifthRetBaggageChecked(false); setSixthRetBaggageChecked(false); setSeventhRetBaggageChecked(false); setEirthRetBaggageChecked(false); updateTotalPrice();}}>Remove</div>{selectedRetCheckbox} Checked Bag.</div>
                )}
                {dropdownOpenCheckedBagReturn === true ? (
    <ul class="custom_drop custom_drop2" /*style={{display: 'block' }}*/ id="outulli">
        {[...Array(Math.min(totalPassengers * 2, 8))].map((_, index) => (
            <li key={index} onClick={() => { 
                setFirstRetBaggageChecked(index === 0);
                setSecondRetBaggageChecked(index === 1);
                setThirdRetBaggageChecked(index === 2);
                setFourthRetBaggageChecked(index === 3);
                setFifthRetBaggageChecked(index === 4);
                setSixthRetBaggageChecked(index === 5);
                setSeventhRetBaggageChecked(index === 6);
                setEirthRetBaggageChecked(index === 7);
                updateTotalPrice(); 
                setDropdownOpenCheckedBagReturn(!dropdownOpenCheckedBagReturn)
            }}>
                <div>{index + 1} Checked Bag.</div> <span>CAD ${(index + 1) * 35}</span>
            </li>
        ))}
    </ul>
) : null}

</div>
                </>			
                ) : (
                  <>
					            {/* <div class="bagga_price">First Bag $35</div>
								<input type="checkbox" checked={firstRetBaggageChecked} onChange={() => { setFirstRetBaggageChecked(!firstRetBaggageChecked); updateTotalPrice();}} />	 */}
                <div class="custom_select pointer">
                {selectedRetCheckbox === null ? (
                <div class="select_button text-left" id="outAddBag" onClick={() => {setDropdownOpenCheckedBagReturn(!dropdownOpenCheckedBagReturn)}}>Add Baggage <i class="bi bi-chevron-down down_arrow"></i></div>
                ) : (
                  <div class="mb10 text-center" id="outBagAddedTxt"><div class="removebaggage" data-name="out" style={{color: 'blue', fontWeight: '500', cursor: 'pointer'}} onClick={() => {setSelectedRetCheckbox(null); updateSelectedRetCheckbox(); setFirstRetBaggageChecked(false); setSecondRetBaggageChecked(false); setThirdRetBaggageChecked(false); setFourthRetBaggageChecked(false); setFifthRetBaggageChecked(false); setSixthRetBaggageChecked(false); setSeventhRetBaggageChecked(false); setEirthRetBaggageChecked(false); updateTotalPrice();}}>Remove</div>{selectedRetCheckbox} Checked Bag.</div>
                )}
                {dropdownOpenCheckedBagReturn === true ? (
    <ul class="custom_drop custom_drop2" /*style={{display: 'block' }}*/ id="outulli">
        {[...Array(Math.min(totalPassengers * 2, 8))].map((_, index) => (
            <li key={index} onClick={() => { 
                setFirstRetBaggageChecked(index === 0);
                setSecondRetBaggageChecked(index === 1);
                setThirdRetBaggageChecked(index === 2);
                setFourthRetBaggageChecked(index === 3);
                setFifthRetBaggageChecked(index === 4);
                setSixthRetBaggageChecked(index === 5);
                setSeventhRetBaggageChecked(index === 6);
                setEirthRetBaggageChecked(index === 7);
                updateTotalPrice(); 
                setDropdownOpenCheckedBagReturn(!dropdownOpenCheckedBagReturn);
            }}>
                <div>{index + 1} Checked Bag.</div> <span>CAD ${(index + 1) * 35}</span>
            </li>
        ))}
    </ul>
) : null}

</div>
                </>		
                )}					
								</div>	
								<div class="seat_icon">	
                {selectedBaggage.checkedBagSecond !== "Kindly refer to the Airlines baggage policy" ? (	
                  <>
					            {/* <div class="bagga_price">Second {extractPrice(selectedBaggage.checkedBagSecond)}</div>
								<input type="checkbox" checked={secondRetBaggageChecked} onChange={() => {setSecondRetBaggageChecked(!secondRetBaggageChecked); updateTotalPrice();}} />		 */}
                </>	
                ) : (
                  <>
					            {/* <div class="bagga_price">Second Bag $35</div>
								<input type="checkbox" checked={secondRetBaggageChecked} onChange={() => {setSecondRetBaggageChecked(!secondRetBaggageChecked); updateTotalPrice();}} />		 */}
                </>	
                )}							
								</div>
								</div>
								</label>
										
									</div>
                  ) : null }
								</div>
								
							<div class="row">
							<div class="col-md-1 discseat">Disclaimer&nbsp;:</div>
							<div class="col-md-11 seat-disc">Airlines have a checked bag weight limits therefore for excess baggage you need to pay a extra fee at the Airport. Additionally your international checked baggage size varies considerably depending on which airline you choose.</div>
							</div>
							</div>
							
					     						
						</div>
						  
						
						<div class="marb15">	
					     <div class="CP_boxsadow">						
						 <h5 class="mar-b">
							 <img src={userIcon} alt="userIcon"/> Auto Check-in 
							 <span>CAD $7.<sup>99</sup> <span class="per-p"> (Per Trip)</span>
							 </span> 
							 </h5>
							</div> 
						<div class="payment-boxsadow">	 
						<div class="row">	 
						<div class="col-md-12 m-top">
						<div class="additional_title">	
						<h4>Save time and rest easy with Web Check-in </h4>							
						<div class="box-panel">                        
	                        <ul>
		                        <li>We'll check you in, so you don't have to remember </li>
		                        <li>Get an assigned seat before reaching the airport* </li>
		                        <li>Skip the counter/kiosk and head to the gate </li>
								<li>Avoid airlines fees for printing a boarding pass </li>
	                        </ul>
                        </div>
							
							<div class="box-panel-last">
							<img src={yesFlight} alt="yesFlight" />
							</div>
							
						<div class="ancelary-box">
						<div class="add-button">				
							
						
						{addToBooking === true ? (
							<div>
						<button type="button" class="mx_wci_add valid" id="ciaddbtn1"><i class="bi bi-check"></i> Added</button>
							
						<button type="button" class="remove-btnw mx_wci_add valid" id="wcibtnremove"  onClick={handleRemove} > Remove </button>
						</div>
						) : (<button type="button" class="mx_wci_add valid active" id="ciaddbtn" onClick={() => {handleAddToBooking(); updateTotalPrice();}} >Add to booking</button>)}						 
						</div>

						<div class="text_note">
						<strong><i class="fa fa-info-circle"></i> Note :</strong> 
						<b>Save time. Enjoy a more relaxed trip.</b> Boarding pass will be emailed. Print them and take with you. For seats and Bags related questions you can call us at 
						<a href="tel:+1-877-658-0111"> +1-877-658-0111</a>
						</div>

						</div>	
							
							
						<div class="clearfix"></div>	
						</div>	
						
						</div>
							
							
						</div>	
							 

				        </div>						
			            </div>
						
						<div class="marb15">
							<div class="CP_boxsadow">
							<h5 class="mar-b"><img src={userIcon} alt="userIcon"/> Flight Cancellation Coverage 
							 <span>CAD $19.<sup>99</sup> <span class="per-p"> (Per Passsenger)</span></span> 
							 </h5>
							</div>
							<div style={{clear: 'both'}}></div>
					     <div class="payment-boxsadow">						
						<div class="row">	 
						<div class="col-md-12 m-top">
						<div class="additional_title">	
						<h4>Additional Perks include</h4>							
						<div class="left-nav">                            
                            <ul>
                                <li>Cancellation without a fee and getting a full refund as well within 24 hours from booking. </li>
                                <li>Option to lock in the cheapest and most incredible airfare before the crowd comes running after it.</li>
                                <li>When there's an error in the reservation, simply cancel and rebook your flights with the correct information at no additional cost.</li>
                            </ul>
                        </div>
							
						<div class="right-nav">
                            <img src={yesIcon} alt="yesIcon" />
                        </div>	
							
						<div class="auto_matick">
                          <div style={{padding: '10px'}}>

                              <div class="toolbar_radio">
                                 <input type="radio" id="checkinyes" name="radioFruit"  checked=" " />
                                  
                                  <input id="cpTakenYes" name="cancellationProtectTaken" onClick={() => {handleFlightCancelSelected(); updateTotalPrice();}}  type="radio" value="Yes" class="valid"  />
                                  
                                  <label for="cpTakenYes" onClick={() => {handleFlightCancelSelected(); updateTotalPrice();}}>Yes, please add the benefits of Pro-Cancellation at CAD $19.99</label>

                                  {/* <!-- <input type="radio" id="checkinno" name="radioFruit" onclick="checkin('no')"> --> */}
                                  
                                  <input id="cpTakenNo" name="cancellationProtectTaken" onClick={handleFlightCancelNotSelected} type="radio" value="No" />
                                  
                                  <label for="cpTakenNo">No, I'd pass on the benefits but I'll consider it maybe later.</label>
                              </div>
                          </div>
                  </div>	
							
							
						<div class="clearfix"></div>	
						</div>	
						
						</div>
							
							
						</div>
				        </div>						
			            </div>
						
						
						
						
											
						
												
						
												
						
						{/* <div class="marb15">
							<div class="toptitle">
							<h5><img src={bundleWhite} alt=""/> Bundle</h5>
							</div>
							 {bundleOffer && (
					     <div class="payment-boxsadow">					       							 
						 <div class="addfullheding">
									Add Entire Bundle At Just $ {bundleOffer.products.reduce((total, product) => {
          return total + parseFloat(product.bundled_pricing.price);
        }, 0)} <input type="checkbox" checked={fullBundleChecked} onChange={handleFullBundleChange} />		
		</div>		 
						   <div class="row">
						   {bundleOffer && bundleOffer.products.map((product, index) => (
							<>	
									
									
			                <div class="col-md-3">
								<label class="paybundle_label">
								<div class="paybage_solid_selected_br">
								<div class="seat_icon">	
								<input type="checkbox" checked={selectedProducts[index]}
                      onChange={() => handleCheckboxChange(index)}/>	
					            <img src={product.icon_url} alt="" />
								</div>
								<div class="fon13">{product.name}</div>	
								<div class="fsize12">{product.short_description}</div>	
					            <div class="bundle_price2"><span>${product.pricing.price.split('.')[0]}.<sup>{product.pricing.price.split('.')[1]}</sup></span> ${product.bundled_pricing.price.split('.')[0]}.<sup>{product.bundled_pricing.price.split('.')[1]}</sup> </div>
								</div>
								</label>
							</div>
							</>
							))}
								
								 
							</div>							 
						   <div class="bundleprice">
								 <p>Know more about the Terms and Conditions of each product by clicking 
									 <a target="_parent" href="https://app.tripadd.com/terms/184a65d2-c43c-43de-9e1e-f5f1acdacfe3">T&C</a> </p>
                   <p>Total Price 
  {selectedProducts.some((isSelected) => isSelected) ? (
    <div>
      {selectedProducts.every((isSelected) => isSelected) ? (
        <span>${totalSelectedPrice.toFixed(2).split('.')[0]}.<sup>{totalSelectedPrice.toFixed(2).split('.')[1]}</sup></span>
      ) : (
        <span>${totalSelectedPrice.toFixed(2).split('.')[0]}.<sup>{totalSelectedPrice.toFixed(2).split('.')[1]}</sup></span>
      )}
    </div>
  ) : (
    <span>$0.<sup>00</sup></span>
  )}
</p>
							 </div>
						</div>	
							)}					 
						</div> */}
            <form id='myForm' onSubmit={handleFinalSubmit}>
						{Array.from({ length: totalPassengers }).map((_, index) => (
						<div class="marb15" ref={nameRef}  /*onSubmit={(e) => {
              e.preventDefault(); // Prevent default form submission
              const isValid = $('#myForm').valid(); // Validate using jQuery
              if (isValid) {
                handleFinalSubmit(); // If valid, proceed with your logic
              }
            }} *//*onSubmit={(e) => e.preventDefault()}*/>
							<div class="toptitle">
							<h5>Traveler Details</h5>
							</div>							
					     <div class="payment-boxsadow">
							 
						<div class="row">
						<div class="traveler_title">
						<h4>Passenger: {index + 1}</h4>
						</div>
							
						<div class="traveler_title">
						<h4 style={{marginBottom: '0px'}}>Title <span>*</span></h4>
						</div>
							
						<div class="col-md-2 m-top" style={{margin: '3px'}}>
						<div class="DofBgroup">
						<table style={{border: 'none'}}>
						<select class="form-control form-select gender" value={bookingRequest.passengers[index]?.title || '0'} ref={titleRef} onChange={(e) => {
        updatePassengerInfo(index, "title", e.target.value);
        //setErrors({ ...errors, title: false });
      }} name='Title'  required>
							<option value="0" >Select</option>
							<option value="Mr" onChange={(e) => {
        updatePassengerInfo(index, "title", e.target.value);
        //setErrors({ ...errors, title: false });
      }}>Mr</option>
							<option value="Mrs" onChange={(e) => {
        updatePassengerInfo(index, "title", e.target.value);
        //setErrors({ ...errors, title: false });
      }}>Mrs</option>
							<option value="Miss" onChange={(e) => {
        updatePassengerInfo(index, "title", e.target.value);
        //setErrors({ ...errors, title: false });
      }}>Miss</option>
                      	</select>
                        
						</table>
						</div>	
            {errors.title && <div >{errors.title}</div>}
						</div>	
							
						<div class="col-md-3 m-top" style={{margin: '3px'}}>						
						<input placeholder="First Name" value={bookingRequest.passengers[index]?.firstName} ref={nameRef} onChange={(e) => {
  const input = e.target.value;
  const filteredInput = input.replace(/[^A-Za-z]/g, ''); // Remove non-alphabet characters
  updatePassengerInfo(index, "firstName", filteredInput);
  setErrors({ ...errors, name: false });
}} class="form-control" type="text" name='firstName' required/>
						{errors.name && <span >{errors.name}</span>}
						</div>
							
						<div class="col-md-3 m-top" style={{margin: '3px'}}>
						<input
  placeholder="Middle Name"
  value={bookingRequest.passengers[index]?.middleName}
  onChange={(e) => {
    const input = e.target.value;
    const filteredInput = input.replace(/[^A-Za-z]/g, '');

    // Correct the typo in the key, and set the filtered input
    updatePassengerInfo(index, "middleName", filteredInput);

    setErrors({ ...errors, middleName: false });
  }}
  class="form-control"
  type="text"
  name='MiddleName'
  //required
/>
						</div>							
						<div class="col-md-3 m-top" style={{margin: '3px'}}>
						<input placeholder="Last Name" value={bookingRequest.passengers[index]?.lastName} ref={lastNameRef} onChange={(e) => {
              const input = e.target.value;
              const filteredInput = input.replace(/[^A-Za-z]/g, '');
        updatePassengerInfo(index, "lastName", filteredInput);
        setErrors({ ...errors, lastName: false });
      }} class="form-control" type="text" name='LastName' required />
      {/* {errors.lastName && <div class="show-error">{errors.lastName}</div>} */}
						</div>
							
						</div>	
							 
						<div class="row">
						<div class="col-md-2 traveler_title m-top" style={{margin: '3px'}}>							
						<h4>Gender <span>*</span></h4>
						<div class="DofBgroup">
						<table style={{border: 'none'}}>
						<select class="form-control form-select gender" value={bookingRequest.passengers[index]?.gender} /*ref={genderRef}*/ onChange={(e) => {
        updatePassengerInfo(index, "gender", e.target.value);
        setErrors({ ...errors, gender: false });
      }} name='Gender' required >
						<option value="0" onChange={(e) => {
        updatePassengerInfo(index, "gender", e.target.value);
        setErrors({ ...errors, gender: false });
      }}>Select</option>                        		
						<option value="1" onChange={(e) => {
        updatePassengerInfo(index, "gender", e.target.value);
        setErrors({ ...errors, gender: false });
      }}>Male</option>                        		
						<option value="2" onChange={(e) => {
        updatePassengerInfo(index, "gender", e.target.value);
        setErrors({ ...errors, gender: false });
      }}>Female</option>
                      	</select>
						</table>
						</div>
            {errors.gender && <div class="show-error">{errors.gender}</div>}	
						</div>	
						<div class="col-md-4 traveler_title m-top" style={{margin: '3px'}}>
						<h4>Date of Birth <span>*</span></h4>
						<div class="DofBgroup">
                        <div class="form-group DOB-devider">
                    <select id="paymentDetails_ExpiryMonth" value={bookingRequest.passengers[index]?.dobMonth} ref={dobMonthRef} onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }} style={{height: '35px', fontSize: '12px'}} class="form-select has-error" aria-describedby="paymentDetails_ExpiryMonth-error" aria-invalid="true" name='DOBMonth' required>
                      <option value="0" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>Month</option>
                      <option value="1" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>Jan (01)</option>
                      <option value="2" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>Feb (02)</option>
                      <option value="3" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>Mar (03)</option>
                      <option value="4" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>Apr (04)</option>
                      <option value="5" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>May (05)</option>
                      <option value="6" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>Jun (06)</option>
                      <option value="7" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>Jul (07)</option>
                      <option value="8" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>Aug (08)</option>
                      <option value="9" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>Sep (09)</option>
                      <option value="10" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>Oct (10)</option>
                      <option value="11" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>Nov (11)</option>
                      <option value="12" onChange={(e) => {
        updatePassengerInfo(index, "dobMonth", e.target.value);
        setErrors({ ...errors, dobMonth: false });
      }}>Dec (12)</option>
                    </select>
                    <div id="paymentDetails_ExpiryMonth-error" class="show-error"></div>
                    {errors.dobMonth && <div class="show-error">{errors.dobMonth}</div>}
                  </div>
			  	        <div class="form-group DOB-devider">
                    
							<select id="paymentDetails_Expirydate"  value={bookingRequest.passengers[index]?.dobDay} ref={dobDayRef} onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }} style={{height: '35px', fontSize: '12px'}} class="form-select has-error" aria-describedby="paymentDetails_Expirydate-error" aria-invalid="true" name='DOBDay' required>
                      <option value="Day" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>Day</option>
						<option value="1" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>1</option>
						<option value="2" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>2</option>
						<option value="3" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>3</option>
						<option value="4" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>4</option>
						<option value="5" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>5</option>
						<option value="6" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>6</option>
						<option value="7" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>7</option>
						<option value="8" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>8</option>
						<option value="9" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>9</option>
						<option value="10" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>10</option>
						<option value="11" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>11</option>
						<option value="12" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>12</option>
						<option value="13" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>13</option>
						<option value="14" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>14</option>
						<option value="15" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>15</option>
						<option value="16" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>16</option>
						<option value="17" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>17</option>
						<option value="18" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>18</option>
						<option value="19" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>19</option>
						<option value="20" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>20</option>
						<option value="21" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>21</option>
						<option value="22" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>22</option>
						<option value="23" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>23</option>
						<option value="24" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>24</option>
						<option value="25" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>25</option>
						<option value="26" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>26</option>
						<option value="27" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>27</option>
      <option value="28" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>28</option>
      <option value="29" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>29</option>
      <option value="30" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>30</option>
      <option value="31" onChange={(e) => {
        updatePassengerInfo(index, "dobDay", e.target.value);
        setErrors({ ...errors, dobDay: false });
      }}>31</option>
                    </select>
                    <div id="paymentDetails_ExpiryMonth-error2" class="show-error"></div>
                    {errors.dobDay && <div class="show-error">{errors.dobDay}</div>}
                  </div>			
                        <div class="form-group">
                    <select id="paymentDetails_ExpiryYear" /*name="paymentDetails.expiryYear"*/ value={bookingRequest.passengers[index]?.dobYear} ref={dobYearRef} onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }} style={{height: '35px', fontSize: '12px'}} class="form-select has-error" aria-describedby="paymentDetails_ExpiryYear-error" aria-invalid="true" name='DOBYear' required>
                      <option value="Year">Year</option>
                      <option value="2025" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2025</option>
      <option value="2024" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2024</option>
                      <option value="2023" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2023</option>
                      <option value="2022" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2022</option>
                      <option value="2021" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2021</option>
                      <option value="2020" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2020</option>
                      <option value="2019" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2019</option>
                      <option value="2018" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2018</option>
                      <option value="2017" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2017</option>
                      <option value="2016" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2016</option>
                      <option value="2015" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2015</option>
                      <option value="2014" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2014</option>
                      <option value="2013" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2013</option>
                      <option value="2012" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2012</option>
                      <option value="2011" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2011</option>
                      <option value="2010" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2010</option>
                      <option value="2009" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2009</option>
                      <option value="2008" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2008</option>
                      <option value="2007" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2007</option>
                      <option value="2006" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2006</option>
      <option value="2005" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2005</option>
      <option value="2004" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2004</option>
      <option value="2003" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2003</option>
      <option value="2002" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2002</option>
      <option value="2001" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2001</option>
      <option value="2000" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>2000</option>
      <option value="1999" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1999</option>
      <option value="1998" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1998</option>
      <option value="1997" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1997</option>
      <option value="1996" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1996</option>
      <option value="1995" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1995</option>
      <option value="1994" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1994</option>
      <option value="1993" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1993</option>
      <option value="1992" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1992</option>
      <option value="1991" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1991</option>
      <option value="1990" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1990</option>
      <option value="1989" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1989</option>
      <option value="1988" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1988</option>
      <option value="1987" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1987</option>
      <option value="1986" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1986</option>
      <option value="1985" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1985</option>
      <option value="1984" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1984</option>
      <option value="1983" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1983</option>
      <option value="1982" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1982</option>
      <option value="1981" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1981</option>
      <option value="1980" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1980</option>
      <option value="1979" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1979</option>
      <option value="1978" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1978</option>
      <option value="1977" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1977</option>
      <option value="1976" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1976</option>
      <option value="1975" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1975</option>
      <option value="1974" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1974</option>
      <option value="1973" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1973</option>
      <option value="1972" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1972</option>
      <option value="1971" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1971</option>
      <option value="1970" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1970</option>
      <option value="1969" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1969</option>
      <option value="1968" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1968</option>
      <option value="1967" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1967</option>
      <option value="1966" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1966</option>
      <option value="1965" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1965</option>

<option value="1964" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1964</option>
      <option value="1963" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1963</option>
      <option value="1962" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1962</option>
      <option value="1961" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1961</option>
      <option value="1960" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1960</option>
      <option value="1959" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1959</option>
      <option value="1958" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1958</option>
      <option value="1957" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1957</option>
      <option value="1956" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1956</option>
      <option value="1955" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1955</option>
      <option value="1954" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1954</option>
      <option value="1953" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1953</option>
      <option value="1952" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1952</option>
      <option value="1951" onChange={(e) => {
        updatePassengerInfo(index, "dobYear", e.target.value);
        setErrors({ ...errors, dobYear: false });
      }}>1951</option>
                    </select>
                    <div id="paymentDetails_ExpiryYear-error" class="show-error">{errors.dobYear}</div>
                    {errors.dobYear && <div class="show-error">{errors.dobYear}</div>}
                  </div>
                        </div>	
						
						</div>
							
						{/* <!--<div class="col-md-3 traveler_title m-top">
						<h4>Phone Number <span>*</span></h4>											
						<input placeholder="Phone Number" class="form-control" type="text" value="" />
						</div>							
						<div class="col-md-3 traveler_title m-top">
						<h4>Email ID <span>*</span></h4>
						<input placeholder="Email ID" class="form-control" type="text" value="" />
						</div>--> */}
							
						</div>
							 
						<div class="row">	 
						<div class="col-md-12">
							
						<h5 class="optional_r" /*style={{display: 'none'}}*/>
						Optional Requests <input type="checkbox" checked={optionalDetails} onChange={handleOptionalDetails} /> <br/> <span style={{fontSize:'12px'}}> (TSA Precheck / Redress Number / Wheelchair)</span>
						</h5>
						{optionalDetails && (		
			     <div class="row" style={{display:''}}>
					<div class="traveler_title">
					 <h4 style={{marginBottom: '0'}}>Emergency Contact Information</h4>
					 </div>
				<div class="col-md-3 m-top">									
			    <input placeholder="emergency contact name" class="form-control" type="text" value={bookingRequest.passengers[index]?.contactName} onChange={(e) => {
  const input = e.target.value;
  const filteredInput = input.replace(/[^A-Za-z]/g, ''); // Remove non-alphabet characters
  updatePassengerInfo(index, "contactName", filteredInput);
  setErrors({ ...errors, name: false });
}} />
				</div>
				<div class="col-md-3 m-top">
				<input placeholder="emergency contact number" class="form-control" type="text" value={bookingRequest.passengers[index]?.contactNumber}  onChange={(e) => {
    const input = e.target.value;
    const filteredInput = input.replace(/[^0-9]/g, '').slice(0, 10);
    updatePassengerInfo(index, "contactNumber", filteredInput);
    //setPhoneNumber(filteredInput.slice(0, 10));
  }} />
					 
				</div>							
				<div class="col-md-3 m-top">
				<input placeholder="TSA Precheck" class="form-control" type="text" value={bookingRequest.passengers[index]?.tsaPrecheck} onChange={(e) => {
    const input = e.target.value;
    const filteredInput = input.replace(/[^0-9A-Za-z]/g, '').slice(0, 9);
    updatePassengerInfo(index, "tsaPrecheck", filteredInput);
    //setPhoneNumber(filteredInput.slice(0, 10));
  }} />
					 
				</div>
				<div class="col-md-3 m-top">
				<input placeholder="Redress Number" class="form-control" type="text" value={bookingRequest.passengers[index]?.reDressNumber} onChange={(e) => {
    const input = e.target.value;
    const filteredInput = input.replace(/[^0-9A-Za-z]/g, '').slice(0, 7);
    updatePassengerInfo(index, "reDressNumber", filteredInput);
    //setPhoneNumber(filteredInput.slice(0, 10));
  }} />
				</div>
					 
				<div class="col-md-3 traveler_title m-top">					
				<h4>Special Request <span>*</span></h4>
				<div class="DofBgroup">	
				<select class="form-control form-select gender" value={bookingRequest.passengers[index]?.splRequest} onChange={(e) => {
        updatePassengerInfo(index, "splRequest", e.target.value);
        //setErrors({ ...errors, title: false });
      }}>
				<option value="0" onChange={(e) => {
        updatePassengerInfo(index, "splRequest", e.target.value);
        //setErrors({ ...errors, title: false });
      }}>Please Select</option>                        		
				<option value="WCHR-Wheelchair for ramp" onChange={(e) => {
        updatePassengerInfo(index, "splRequest", e.target.value);
        //setErrors({ ...errors, title: false });
      }}>WCHR-Wheelchair for ramp</option>                        		
				<option value="WCHS-Wheelchair for step" onChange={(e) => {
        updatePassengerInfo(index, "splRequest", e.target.value);
        //setErrors({ ...errors, title: false });
      }}>WCHS-Wheelchair for step</option>
      <option value="WCHC-Wheelchair for cabin seat" onChange={(e) => {
        updatePassengerInfo(index, "splRequest", e.target.value);
        //setErrors({ ...errors, title: false });
      }}>WCHC-Wheelchair for cabin seat</option>
      <option value="BLND-Blind passenger" onChange={(e) => {
        updatePassengerInfo(index, "splRequest", e.target.value);
        //setErrors({ ...errors, title: false });
      }}>BLND-Blind passenger</option>
      <option value="DEAF-Deaf passenger" onChange={(e) => {
        updatePassengerInfo(index, "splRequest", e.target.value);
        //setErrors({ ...errors, title: false });
      }}>DEAF-Deaf passenger</option>
				</select>
				</div>	 
				</div>
				
				<div class="col-md-12 traveler_title m-top">					
				<div class="note-in">	
						<span>Note:</span> The above-mentioned services will depend on the airline's confirmation. These services are solely at the discretion of the airline. 
						</div>
					 
				</div>
				</div>
						)}
				</div>							 
				</div>	 


				</div>		
        	
			            </div>
						))}
            <div class="container-fluid" style={{margin: '20px 0'}}>
							 <div class="row">			
			                <div class="col-md-12 flight-price-select1" style={{textAlign: 'center'}}>
							
                      <button type="submit" /*onClick={() => handleFinalSubmit()}*/>Continue</button>
							</div>						
							</div>
							 
							 
						</div>
						</form>
						
						
						
						
											
						
						
					
					</div>	
					  
					  <div class="col-md-3">
					    <div class="marb15 is-sticky">	
						<div className='FareLadderPayment'	style={{ marginTop: `${marginTop}px`, marginRight: '10px' }}>				
					  <div class="payment-boxsadow">
						  <h3 class="paymentfare_title">Price Summary</h3>
						  
							<div class="booked-fare-area">
							<ul>
							<li><span>Adult ({(formData && formData.adults ? parseInt(formData.adults) : 0)}xCAD ${selectedFlight.fare.adultFare.toFixed(2).split('.')[0]}.<sup>{selectedFlight.fare.adultFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>CAD ${totalPriceAdults.split('.')[0]}.<sup>{totalPriceAdults.split('.')[1]}</sup></span> </li>
							{ (formData && formData.child ? parseInt(formData.child) : 0) ? (
							<li><span>Child ({(formData && formData.child ? parseInt(formData.child) : 0)}xCAD ${selectedFlight.fare.childFare.toFixed(2).split('.')[0]}.<sup>{selectedFlight.fare.childFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>CAD ${totalPriceChild.split('.')[0]}.<sup>{totalPriceChild.split('.')[1]}</sup></span> </li>
							) : null}

                            { (formData && formData.infants ? parseInt(formData.infants) : 0) ? (
							<li><span>Infant<sub>Lap</sub> ({(formData && formData.infants ? parseInt(formData.infants) : 0)}xCAD ${selectedFlight.fare.infantFare.toFixed(2).split('.')[0]}.<sup>{selectedFlight.fare.infantFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>CAD ${totalPriceInfant.split('.')[0]}.<sup>{totalPriceInfant.split('.')[1]}</sup></span> </li>
							) : null}

                            { (formData && formData.infantsWs ? parseInt(formData.infantsWs) : 0) ? (
							<li><span>InfantsWs ({(formData && formData.infantsWs ? parseInt(formData.infantsWs) : 0)}x${selectedFlight.fare.infantWsFare.toFixed(2).split('.')[0]}.<sup>{selectedFlight.fare.infantWsFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>CAD ${totalPriceInfantWs.split('.')[0]}.<sup>{totalPriceInfantWs.split('.')[1]}</sup></span> </li>
							) : null}
              
							{selectedCard !== null ? (
							<li><span>{selectedCard === 0 ? 'Window' : selectedCard === 1 ? 'Middle' : selectedCard === 2 ? 'Aisle' : null} Seat </span> <span>CAD ${totalPriceSeat.toFixed(2).split('.')[0]}.<sup>{totalPriceSeat.toFixed(2).split('.')[1]}</sup></span> </li>
							) : null }
              {selectedDepFirstCheckbox !== null || selectedDepCheckbox !== null || selectedRetFirstCheckbox !== null || selectedRetCheckbox !== null ? (
                <>
                
          <li style={baggageInfo === true ? { borderBottom: 'none' } : {}}>
            <span onClick={() => {setBaggageInfo(!baggageInfo)}} style={{cursor: 'pointer'}}>Baggage  <i onClick={() => {setBaggageInfo(!baggageInfo)}} class="fa fa-angle-down "></i></span>
           
            {/* {selectedBaggage.carryOnBag !== "NO" ? (
            <span>Included</span>
            ) : ( */}
              <span>CAD ${totalBaggagePrice}</span>
            {/* // <span>${(selectedDepFirstCheckbox === 'both' ? 40 : 20).toFixed(2).split('.')[0]}.<sup>{(selectedDepFirstCheckbox === 'both' ? 40 : 20).toFixed(2).split('.')[1]}</sup></span> */}
            {/* // <span>${(secondDepFirstBaggageChecked === true ? 40 :  */}
            {/* //   thirdDepFirstBaggageChecked === true ? 60 :  */}
            {/* //   forthDepFirstBaggageChecked === true ? 80 :  */}
            {/* //   fifthDepFirstBaggageChecked === true ? 100 :  */}
            {/* //   sixthDepFirstBaggageChecked === true ? 120 :  */}
            {/* //   seventhDepFirstBaggageChecked === true ? 140 :  */}
            {/* //   eirthDepFirstBaggageChecked === true ? 160 : 20 ) */}
            {/* //  .toFixed(2) */}
            {/* //  .split('.')[0]}.<sup></sup></span> */}
            {/* )} */}
             </li>
          {/* <div style={{float: 'left', width: '100%', paddingLeft: '10px', marginBottom: '10px'}}>
              Baggage   <div class="price">$4766<sup>.00</sup></div> 
          </div> */}
              {baggageInfo === true ? (
                <>
                <div>
                
          <div class="segment_price" style={{float: 'left', padding: '0 10px', width: '100%' , borderBottom: '1px solid #ddd'}}>
          <div id="dep_coch">
          {/* <!-- Depart --> */}
          
          <h5 style={{fontSize: '14px', marginBottom: '0px', color: '#4f8ffc'}}>Depart</h5>    
          {selectedDepFirstCheckbox !== null ? (
            <>        
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
          <div class="col-xs-6">{selectedDepFirstCheckbox} Carry-on.</div>
          <div class="col-xs-6 text-right">CAD ${(secondDepFirstBaggageChecked === true ? 40 : 
              thirdDepFirstBaggageChecked === true ? 60 : 
              forthDepFirstBaggageChecked === true ? 80 : 
              fifthDepFirstBaggageChecked === true ? 100 : 
              sixthDepFirstBaggageChecked === true ? 120 : 
              seventhDepFirstBaggageChecked === true ? 140 : 
              eirthDepFirstBaggageChecked === true ? 160 : 20 )
             .toFixed(2)
             .split('.')[0]}{/*<sup>.00</sup>*/}</div>
             </div>
             </>
             ) : null}
             {selectedDepCheckbox !== null ? (
             <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
          <div class="col-xs-6">{selectedDepCheckbox} Checked Bag.</div>
          <div class="col-xs-6 text-right">CAD ${(secondDepBaggageChecked === true ? 70 : 
              thirdDepBaggageChecked === true ? 105 : 
              fourthDepBaggageChecked === true ? 140 : 
              fifthDepBaggageChecked === true ? 175 : 
              sixthDepBaggageChecked === true ? 210 : 
              seventhDepBaggageChecked === true ? 245 : 
              eirthDepBaggageChecked === true ? 280 : 35 
            )}</div>
          </div>
             ) : null }
          {/* <!-- Depart End--> */}
          </div>

          <div id="ret_coch">
          {/* <!-- Return --> */}
          
          <h5 style={{fontSize: '14px', marginBottom: '0px', color: '#4f8ffc'}}>Return</h5>   
          {selectedRetFirstCheckbox !== null ? (
            <>         
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
              <div class="col-xs-6">{selectedRetFirstCheckbox} Carry-on</div>
              <div class="col-xs-6 text-right">CAD ${(secondRetFirstBaggageChecked === true ? 40 : 
              thirdRetFirstBaggageChecked === true ? 60 : 
              fourthRetFirstBaggageChecked === true ? 80 : 
              fifthRetFirstBaggageChecked === true ? 100 : 
              sixthRetFirstBaggageChecked === true ? 120 : 
              seventhRetFirstBaggageChecked === true ? 140 : 
              eirthRetFirstBaggageChecked === true ? 160 : 20 )
             .toFixed(2)
             .split('.')[0]}</div>
              </div>
              </>
          ) : null }
          {selectedRetCheckbox !== null ? (
              <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
              <div class="col-xs-6">{selectedRetCheckbox} Checked Bag.</div>
              <div class="col-xs-6 text-right">CAD ${(secondRetBaggageChecked === true ? 70 : 
              thirdRetBaggageChecked === true ? 105 : 
              fourthRetBaggageChecked === true ? 140 : 
              fifthRetBaggageChecked === true ? 175 : 
              sixthRetBaggageChecked === true ? 210 : 
              seventhRetBaggageChecked === true ? 245 : 
              eirthRetBaggageChecked === true ? 280 : 35 )
             .toFixed(2)
             .split('.')[0]}</div>
              </div>
          ) : null}
          {/* <!-- Return End--> */}
          </div>
          </div>                
              
           </div>    
          <div style={{clear: 'both'}}></div>
          </>
          ) : null }
         
          
          </>
        ) : null}
							
							{addToBooking !== false ? (
							<li><span>Auto Check In </span> <span>CAD $7.<sup>99</sup></span> </li>
							) : null}
							{flightCancel !== false ? (
							<li><span>Flight Cancellation </span> <span>CAD $19.<sup>99</sup></span> </li>
							) : null}
							{/* <li><span>Flight Alert </span> <span>$7.<sup>99</sup></span> </li>	 */}
							{/* {selectedProducts.some((isSelected) => isSelected) && (
								<div>
								{selectedProducts.every((isSelected) => isSelected) ? (

							<li style={{borderBottom: '0px'}}><span>Bundle </span> <span>${totalSelectedPrice.toFixed(2).split('.')[0]}.<sup>{totalSelectedPrice.toFixed(2).split('.')[1]}</sup></span> </li>	
							) : (
								<li style={{borderBottom: '0px'}}><span>Bundle selected: </span> <span>${totalSelectedPrice.toFixed(2).split('.')[0]}.<sup>{totalSelectedPrice.toFixed(2).split('.')[1]}</sup></span> </li>
								
							  )}
							  </div>
							)} */}
              {selectedFlight.fare.totalTax !== 0.0 ? (
							<li><span>Taxes</span>  <span>CAD ${roundedTax.split('.')[0]}.<sup>{roundedTax.split('.')[1]}</sup></span> </li>
              ): null}
							</ul>

							<div class="paysubTotal-booked">
							<h6> Total Price : <div style={{float: 'right'}}>CAD $<span>{totalPriceDisplay}<sup></sup></span></div></h6> {/*<sup>11</sup>*/}
								
								
								
							</div>

							<p class="pay-note"> 
							<strong> Note:-</strong> All Price are quoted in USD.Your credit/debit card may be billed in multiple charges totaling the final total price.	Baggage allowance and fee amount are not guaranteed and are subject to change by the airline.

							</p>

							</div>
						  
						  
						  </div>
						</div>	
					 </div>
						  
					   
					  </div>
					  
				    </div>
				  
				  </div>
				</div>
				
		      </div>
				
			</div> 
      <Footer2 />
			</>
  )
}
