import React from 'react'

export default function footer2() {
  return (
    <div className="copyright-block">
        <div className="container">
        <p style={{textAlign: "center ", color: 'white', fontSize: '13px'}}>Rawfares.ca is represented by Holiay Breakz Group LLC which is registered in 55 village centre Pl,C/o:Incorp Pro,Suite 200, Mississauga, Ontario, Canada, L4Z1V9<br />Rawfares.ca is an independent travel portal with no third party association. By using Rawfares.ca, you agree that Rawfares is not accountable for any loss - direct or indirect, arising of offers, materials or links to other sites found on this website. In case of queries, reach us directly at our Contact Number +1-888-679-3941 or, simply email at support@rawfares.ca</p>
            <div className="copyright"> Copyrights &copy; 2022-2025 rawfares.ca, All Rights Reserved. </div>
        </div>
    </div>
  )
}
